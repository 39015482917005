import react from 'react'
import BackgroundImage, { implyAspectRatio } from './BackgroundImage'
import { Typography, useTheme } from '@mui/material'
import DownloadButton from './DownloadButton'

export default function ArticlesOfIncorporation() {
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 100
    }}>
      <div>
        <BackgroundImage src={require("../assets/Incorporation1952Top.jpg")} width={500} aspectRatio={307 / 535} />
        <BackgroundImage src={require("../assets/Incorporation1952Bottom.jpg")} width={500} aspectRatio={452 / 534} />
        <div style={{ height: 20 }} />
        <DownloadButton text={"Articles of Incorporation 1952"} url={require("../assets/ArticlesofIncopration1952.pdf")} />
      </div>
      <div style={{
        width: "30vw"
      }}>
        <Typography variant="h5" align="left" >
          CONSENT TO FIRST MEETING OF DIRECTORS
          OF SAN PEDRO ANNUAL FESTIVAL
          <br />
          <br />
        </Typography>
        <Typography variant="body1" align="center" >
          --oOo--
          <br />
          <br />
        </Typography>
        <Typography variant="body1" align="left" >
          We, the undersigned, being all the Directors of SAN
          PEDRO ANNUAL FESTIVAL,do hereby give our consent to the holding
          of this, the first meeting, of the Board of Directors of the said
          SAN PEDRO ANNUAL FESTIVAL, this 11th day of July, 1952, at the
          hour of 6 P. M., at Bryte, California , the County of
          Yolo, State of California, and we hereby give our written
          consent to the transaction of all business which may come before
          the said meeting.
          <br />
          <br />
          ...
          <br />
          <br />
          IN WITNESS THEREOF, we have hereunto subscribed our
          names this 11th day of July, 1952.
        </Typography>
        <Typography align="left" style={{ paddingLeft: 50 }}>
          <b>
            <br />
            Domingos G. Sequeira
            <br />
            Francisco Carvalho
            <br />
            Albino A. Freitas
            <br />
            Frank Neves
            <br />
            Jordon P. Ramos
            <br />
            Antonio G. Silva
            <br />
            Antonio P. Ramos
            <br />
            Noe P. Ramos
            <br />
            John Rodrigues
            <br />
            Tony J. Pinto
            <br />
            Arthur Lawrence
          </b>
        </Typography>
      </div>
    </div>
  )
}