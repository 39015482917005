import React, {useState} from 'react'
import DownloadButton from './DownloadButton'
import Countdown from './Countdown'
import { Paper, Typography } from '@mui/material'

function GoogleCalendarIFrame() {
  return (
    <div>
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&title=San%20Pedro%20Event%20Calendar&src=c2FucGVkcm9icnl0ZUBnbWFpbC5jb20&color=%23039BE5"
        style={{ border: "solid 1px #777" }} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
    </div>
  )
}

function DaysUntilNextEvent() {
  const endDate = new Date('2024-06-29T17:00:00')
  console.log(endDate)
  return (
    <Paper elevation={12} style={{
      padding: 20, 
      backgroundImage: "linear-gradient(black, #581845)", 
      borderRadius: 20, 
      display: "flex", 
      flexDirection: "column", 
      gap: 20
    }}>
      <Typography variant="h4" style={{color: "white" }}>
        <b>Countdown to San Pedro Festa 2024</b>
      </Typography>
      <Countdown endDate={endDate} />
    </Paper>
  )
}

export default function CalendarPage() {
  const [attempts, setAttempts] = useState(0)
  const [topHeight, setTopHeight] = useState(document.getElementById("top-bar")?.getBoundingClientRect()?.height)
  const [menuHeight, setMenuHeight] = useState(document.getElementById("menu-bar")?.getBoundingClientRect()?.height)
  const minHeight = `calc(100vh - ${topHeight}px - ${menuHeight}px)`
  if ((topHeight === undefined || menuHeight === undefined) && attempts < 10) {
    setTimeout(() => {
      setAttempts(attempts + 1)
      setTopHeight(document.getElementById("top-bar")?.getBoundingClientRect()?.height)
      setMenuHeight(document.getElementById("menu-bar")?.getBoundingClientRect()?.height)
    }, 100)
  }
  console.log(minHeight)
  return (
    <div 
      className="faded-background"
      style={{
        "--b": `url(${require("../assets/DenisSharonTable.jpg")})`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        // paddingTop: 20,
        // paddingBottom: 20,
        gap: 100,
        minHeight: minHeight
    }}>
      <GoogleCalendarIFrame />
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: 100
      }}>
        <DownloadButton text={"San Pedro Full Calendar 2024 Pdf"} url={require("../assets/SanPedroCalendar2024.pdf")} />
        <DaysUntilNextEvent />
      </div>
    </div>
  )
}

