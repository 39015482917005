import React, { useState, useEffect } from 'react'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import BackgroundImage from './BackgroundImage';
import { fetchGoogleDriveFolder, googleDriveFileUrl } from './googleDriveApi'
import GallerySlides from '../assets/GallerySlides/GallerySlides'

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Gallery({ fileIds, width, height, interval, setModalUrl }) {
  const imageUrls = Object.values(GallerySlides)
  return (
    <div>
      <AutoplaySlider
        style={{
          width: width,
          height: height
        }}
        play={true}
        cancelOnInteraction={false}
        interval={interval}
        transitionDelay={1000}
      >
        {
          imageUrls.map(url => {
            const handleClick = () => {
              if (setModalUrl) { setModalUrl(url) }
            }
            return (
              <div key={url} onClick={handleClick}>
                <BackgroundImage key={url} src={url} width={width} height={height} />
              </div>
            )
          })
        }
      </AutoplaySlider>
    </div>
  )
}

/*
{
  fileIds.map(fileId => {
    const src = googleDriveFileUrl(fileId)
    const handleClick = () => {
      if (setModalUrl) { setModalUrl(src) }
    }
    return (
      <div key={fileId} onClick={handleClick}>
        <BackgroundImage key={fileId} src={src} width={width} height={height} />
      </div>
    )
  })
}
<img src={src} referrerPolicy='no-referrer' hidden={true} />
*/

export default function GoogleDriveGallery({ folderId, width = "70vh", height = "50vh", intervalMs = 6000, maxImages = 10, setModalUrl }) {
  const [kickStart, setKickStart] = useState(false)
  const [files, setFiles] = useState([])
  useEffect(() => {
    const fetchGoogleDriveFiles = async () => {
      const folderData = await fetchGoogleDriveFolder(folderId)
      folderData.files.sort(() => Math.random() - 0.5)
      setFiles(folderData.files)
    }
    fetchGoogleDriveFiles()
  }, []);

  if (files.length > 0 && !kickStart) {
    setTimeout(() => {
      const nextButton = document.getElementsByClassName("awssld__next")
      console.log(nextButton)
      console.log("Manually clicking awssld__next button due to underlying bug :(")
      nextButton[0].click()
    }, intervalMs)
    setKickStart(true)
  }

  const fileIds = files.map(file => file.id)
  return (
    <Gallery fileIds={fileIds} width={width} height={height} interval={intervalMs} setModalUrl={setModalUrl} />
  )
}