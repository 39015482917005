import React from 'react'
import { Typography } from '@mui/material'

export default function ParkRentalsPage() {
  return (
    <div
      style={{
        padding: "10px 15% 0 15%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 20
      }}
    >
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center"
      }}>
        <Typography variant="h6" align="left">
          Contact: Larry Freitas, Park Coordinator<br/>
          <i>sanpedroparkrentals@gmail.com</i>
        </Typography>
        <img src={require("../assets/ParkRentalFence.jpeg")} alt="" />
        <Typography variant="body1" align="left">
          San Pedro Park is available for rental for your wedding, anniversary, baby shower, birthday,
          baptism, memorial, quinceanera, and family celebrations for up to <b>200 guests</b>. The park is fully
          fenced in to help keep your event safe. Park rental hours are from <b>8am to 11pm</b>. Rental dates
          are limited to Saturday's only from <b>May 1<sup>st</sup> through September 30<sup>th</sup></b> each year.
          The Park is a very family friendly park that includes many mature shade trees, shade cloth
          covered dance floor, electrical hook ups for live band or DJ, lighting throughout for evening
          events, refreshment booth, game booth, Men's and Women's bathrooms, grass lawn, BBQ pits,
          indoor kitchen prep area with refrigerator, warming oven and ice machine. Tables and chairs
          are available for up to 200 people. WiFi is available throughout the park. A lot across the street
          provides parking for up to 25 vehicles plus lots of street parking.
        </Typography>
      </div>
      <div>
        <img src={require("../assets/ParkRentalParkTables.jpeg")} alt="" />
        <img src={require("../assets/ParkRentalField.jpeg")} alt="" />
      </div>
    </div>
  )
}