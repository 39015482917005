const API_KEY = "AIzaSyBOdIUA8x7pBAddL9zKVFJebfRoY-44RiQ"
export async function fetchGoogleDriveFolder(folderId) {
  // https://developers.google.com/drive/api/reference/rest/v3/files
  const fileFields = "files(id,name,description)"
  const driveUrl = `https://www.googleapis.com/drive/v3/files?q="${folderId}"+in+parents&fields=${fileFields}&key=${API_KEY}`
  const data = await fetch(driveUrl)
  const response = await data.json()
  console.log(response)
  return response
}

export function googleDriveFileUrl(fileId) {
  return `https://drive.google.com/uc?id=${fileId}`
  // return `https://lh3.google.com/u/0/d/${fileId}`
}

export function isGoogleDriveUrl(src) {
  return src?.includes("drive.google.com")
}

export function downloadableGoogleDriveUrl(src) {
  if (isGoogleDriveUrl(src)) {
    return `${src}&export=download`
  }
}

export async function fetchGoogleDriveFile(fileId) {
  const driveUrl = `https://drive.google.com/uc?id=${fileId}`
  // const driveUrl = `https://lh3.google.com/u/0/d/${fileId}`
  const data = await fetch(driveUrl)
  const response = await data.json()
  // console.log(response)
  return response
}