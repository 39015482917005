import { useParams } from 'react-router-dom'
import React from 'react'
import smadeira from "../legacy/smadeira"
import shist from '../legacy/shist'
import isabel from '../legacy/isabel'

const FILES = {
  "smadeira.htm":  smadeira,
  "shist.htm":  shist,
  "isabel.htm": isabel
}

export default function LegacyPage() {
  const { filename } = useParams()
  if (FILES?.[filename]) {
    return FILES?.[filename]
  } else {
    return (
      <div>
        Could not find {filename}
      </div>
    )
  }
}