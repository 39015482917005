import React, { useState, useEffect } from 'react'
import BackgroundImage from './BackgroundImage'
import PaypalButton from './PaypalButton'
import { Typography } from '@mui/material'

function MembershipIFrame() {
  return <iframe 
    id="membership-i-frame"
    src="https://docs.google.com/forms/d/e/1FAIpQLSfn7gXa4BieZrIMQOOH5auwDQ9AOVFn1jfrWMS3B0DKP2rhrg/viewform?embedded=true" 
    width="640" height="1600" frameBorder="0" marginHeight="0" marginwidth="0">Loading…</iframe>
}

export default function GetInvolvedPage() {
  return (
    <div className='faded-background' style={{
      "--b": `url(${require('../assets/GetInvolved.jpg')})`,
      display: "flex",
      flexDirection: "column",
      paddingTop: 20,
      paddingBottom: 20,
      gap: 20,
      alignItems: "center"
    }}>
      <MembershipIFrame/>
      <PaypalButton />
    </div>
  )
}