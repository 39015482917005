import { Modal, Paper, IconButton } from '@mui/material'
import { Clear, Download } from '@mui/icons-material';
import { downloadableGoogleDriveUrl, isGoogleDriveUrl } from './googleDriveApi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: "20px",
  outline: 'none',
  display: "flex",
  flexDirection: "column",
  gap: "20px"
};

export default function GalleryModal({ modalUrl, handleModalClose }) {
  const handleDownload = () => {
    const link = document.createElement("a");
    if (isGoogleDriveUrl(modalUrl)) {
      modalUrl = downloadableGoogleDriveUrl(modalUrl)
    }
    link.download = modalUrl;
    link.href = modalUrl;
    link.click();
  };
  return (
    <Modal
      open={Boolean(modalUrl)}
      onClose={handleModalClose}
      disableEnforceFocus
    >
      <Paper sx={style} elevation={4}>
        <img src={modalUrl} style={{ maxWidth: "80vw", maxHeight: "80vh" }} />
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
          <IconButton
            size="large"
            color="inherit"
            style={{ margin: "0px 10px", fill: "black" }}
            onClick={handleDownload}
          >
            <Download />
          </IconButton>
          <IconButton
            size="large"
            color="inherit"
            style={{ margin: "0px 10px" }}
            onClick={handleModalClose}
          >
            <Clear />
          </IconButton>
        </div>
      </Paper>
    </Modal>
  )
}