import React from 'react'
import {PAYPAL_FORM_ID, HiddenPaypalForm} from './PaypalButton'
import { Button, Typography } from '@mui/material'

function SanPedroFacebook({vh=0.6, cropTop=0.5}) {
  // Calcualte height/width
  const aspectRatio = 720 / 1280  
  const w = window.innerWidth
  const h = w * aspectRatio

  const horizontallySquished = vh * window.innerHeight > h

  const height = horizontallySquished ? `${h}px` : `${100 * vh}vh` 
  const width = `${window.innerWidth}px`
  const aspectHeight = `calc(${width} * ${aspectRatio})`
  const totalCrop = horizontallySquished ? "0px" : `calc(${aspectHeight} - ${height})`
  const marginTop = `calc(-1 * ${totalCrop} * ${cropTop})`

  return (
    <div
      style={{
        width: "100%",
        height: height,
        overflow: "hidden",
      }}
    >
      <div style={{
        marginTop: marginTop
      }}>
        <iframe width={w + "px"} height={h + "px"}
          src="https://www.youtube.com/embed/WnHTx6dfCIw?&autoplay=1&loop=1&mute=1&playsinline=1&controls=0&showinfo=0&playlist=WnHTx6dfCIw"
          frameBorder="0">
        </iframe>
      </div>
    </div>
  )
}

function SanPedroWelcomeMessage() {
  const handleClick = () => {
    document.getElementById(PAYPAL_FORM_ID).submit()
  } 

  return (
    <div
      style={{
        paddingLeft: "15%",
        paddingRight: "15%",
        display: "flex",
        flexDirection: "row",
        gap: 50
      }}
    >
      <Typography variant="h6">
        <b>Bom dia, bom povo!</b> The San Pedro Association is a Madeiran Portuguese Heritage Association focused
        on maintaining its historical presence in the West Sacramento community and
        preservation of its historical San Pedro Park. <b>Obrigado!</b>
      </Typography>
      <Button variant="contained" style={{width: 250}} onClick={handleClick}>
        DONATE NOW
        <HiddenPaypalForm />
      </Button>
    </div>
  )
}

function NonprofitMessage() {
  return (
    <div
      style={{
        paddingLeft: "20%",
        paddingRight: "20%"
      }}
    >
      <Typography variant="body2">
        A California Exempt Nonprofit Mutual Benefit Corp.(rtc23701f), (C 0272992)
        <br />
        An IRS 501c (7) Exempt Corp., FTxID/EIN: 02-0760640, (eff: 12/6/2005)
      </Typography>

    </div>
  )
}


export default function HomePage() {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: 20,
      alignItems: "center"
    }}
    >
      <SanPedroFacebook />
      <SanPedroWelcomeMessage />
      {/* <PaypalButton /> */}
      {/* <NonprofitMessage /> */}
    </div>
  )
}