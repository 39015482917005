let htmlAsString = `<html>
 <head>
  <title>
   Maria J. Cirurgiao and Michael D. Hull
  </title>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
  <meta content="Microsoft FrontPage 4.0" name="GENERATOR"/>
  <meta content="FrontPage.Editor.Document" name="ProgId"/>
 </head>
 <body bgcolor="#FFFFFF" text="#000000">
  <div align="center">
   <center>
    <table border="0" width="550">
     <tr>
      <td>
       <b>
        <font face="Arial,Tahoma" size="4">
         ELIZABETH OF PORTUGAL: 'FOR, IN HER IS
A SPIRIT INTELLIGENT, HOLY, UNIQUE'
        </font>
       </b>
      </td>
     </tr>
     <tr>
      <td>
       <b>
        <font face="Arial,Tahoma">
         Maria J. Cirurgiao and Michael D. Hull
        </font>
       </b>
       <hr/>
      </td>
     </tr>
     <tr>
      <td>
       <b>
        <font face="Arial,Tahoma" size="4">
        </font>
        <font face="Arial,Tahoma">
        </font>
       </b>
       Centuries
before July 4th came to commemorate Western civilization's great experiment in
democracy and America's birthday, the date was principally associated with the
name of one of the most extraordinary women in history-St. Elizabeth (Isabel) of
Portugal. She died on July 4th, 1336 at the age of 65 and thus her name marks this date on the Church
calendar, silently attesting to the existence of a woman who, in her passage
through this earth, had it all and turned it all to good.
       <p>
        From powerful monarchs to outcast lepers, many lives were touched and healed
by the 13thcentury princess, child bride, queen, wife, and mother. Yet too few
people, even among Catholics, are acquainted with her. The definitive biography
of St. Elizabeth has yet to be written, but persistent searches of archival
sources have yielded valuable documents that, added to what is commonly known of
her life, present us with a portrait of a woman whose spiritual depths,
intellectual stature, and human presence force us to revise any notions we may
have formed about medieval women in high places.
       </p>
       <p>
        Princess Elizabeth (Isabel) of Aragon, who became the queen of King Dinis of
Portugal, and ultimately was raised to the honors of the altar as St. Elizabeth
of Portugal, was born in Saragossa, Spain, around 1271. The daughter of King
Pedro III of Aragon and Queen Constanza, she was named for her great-aunt St.
Elizabeth of Hungary. Elizabeth grew up in a family of six brothers and sisters.
Her childhood days were regularly divided between her studies, her sewing, her
prayers-kneeling, she accompanied her chaplain at the Divine Office on a daily
basis-and recreation. She was deemed a great beauty, very early in life.
       </p>
       <p>
        Before Elizabeth entered her teen years, several European monarchs sought her
hand. King Edward IV of England solicited her for his son, the crown prince, as
did the king of Sicily, the king of France, and others. As was the custom,
Elizabeth's parents weighed the political advantages of each proposed match. The
greatest benefit, they concluded, would ensue from a matrimonial alliance with
King Dinis of Portugal. Elizabeth became his wife, by proxy. She was around 12
years of age, while King Dinis was 20.
       </p>
       <p>
        One cannot help but wonder how much the young princess understood of the
document she signed, to be delivered to a man she had never met. It read,
"I, Elizabeth, daughter of the Most Illustrious Don Pedro, by the grace of
God king of Aragon, hereby bestow my body as the legitimate wife of Dom Dinis,
king of Portugal and of the Algarve, in his absence as if he were
present...."
       </p>
       <p>
        A year and a half later, in June 1282, Elizabeth arrived in Portugal to start
her new life as wife and queen.
       </p>
       <b>
        <font size="4">
         <p>
          A Lack of Domestic Tranquility
         </p>
        </font>
       </b>
       <p>
        Chroniclers are in accord over the delight with which her subjects greeted
their enchanting new queen. She was hardly more than a child, but in her bearing
they detected already virtues that boded well for the nation. Was her husband
equally enchanted? It is difficult to say. Life in the Middle Ages was not
conducive to domestic tranquility in a royal household. Effective government in
those times of poor communications demanded that a ruler maintain contact with
his subjects by touring his lands. Transportation was cumbersome, usually by
mule, and a king would lodge, along with his retinue, at the residence of one of
his vassals. There the king would hear lawsuits, establish laws, and deal with
other administrative issues. The queen had her own house, or houses. Knowing
this affords us some insight into the fact that Elizabeth had only two children
by her young and virile husband, who fathered an additional seven children-one
chronicler says nine-by a number of other women. Elizabeth's daughter,
Constanza, was born after the couple had been married for eight years, and
Afonso, the crown prince, a year later.
       </p>
       <p>
        While we may feel outraged at the undeserved betrayal of the young Elizabeth,
she never sought the pity of those around her: There is no record of her showing
jealousy or condemning her husband's behavior. Elizabeth shielded her wounds
from prying eyes. A legend survives that, late one night, as the king was
returning to her quarters, she sent some pages to meet him with lighted torches,
and with this message: "We have come, your lordship, to light your course,
for unseeing you go straying off these paths." Biographers maintain,
however, that the legend jars with the character of Elizabeth. She, who never
did address a word of reproach to her husband in front of witnesses, would
certainly not do it through her pages.
       </p>
       <p>
        But we ought not to suppose that Elizabeth never remonstrated in private with
the man who so flagrantly broke his marriage vows. Elizabeth's natural emotions
were not impaired, nor are great saints made in a vacuum of human passions.
Fortunately for us, King Dinis was a gifted poet and his poetry has been
preserved. We can turn to it for evidence that the "troubadour king,"
as Dinis is known in literary circles, was fully aware of the treasure he had in
a wife who covered his sins. In one particular poem, one of 72 courtly love
songs addressed to a variety of ladies, real or imaginary, we find these
self-reflective lines which amount to a veritable examination of conscience:
       </p>
       <p>
        I don't know how to justify myself to my lady,
        <br/>
        Should God lead me to stand before her eyes;
        <br/>
        Once I'm before her she will adjudge me
        <br/>
        Her betrayer, and with plenty of reason.
       </p>
       <p>
        Thoughts of Elizabeth's excellence did clearly make their way into her
husband's verse. But for a substantial appreciation of her unique qualities we
need to look elsewhere. A book survives from the 14th century, relating facts of
the "worthy life" of the holy queen, thus attesting that centuries
before she was canonized-in 1625, by Pope Urban VIII-and long before the
invention of the printing press, her life, her person, and her accomplishments
were held to have been extraordinary and to warrant a written record.
       </p>
       <b>
        <font size="4">
         <p>
          The Immaculate Conception
         </p>
        </font>
       </b>
       <p>
        Elizabeth's Christian faith informed every aspect of her existence. She
surrounded herself with a number of chaplains, and every day she recited, and
sang, the Liturgy of the Hours with them. And if one of them ever misread the
Latin in her presence, Elizabeth quickly corrected him, for she herself knew
Latin as thoroughly as she knew the vernacular.
       </p>
       <p>
        One can only speculate as to how much time a queen-this particular queen,
at any rate-could devote to reading or studying. But it was Elizabeth who, in
1320, obtained of the bishop of Coimbra a formal proclamation establishing the
celebration of the Immaculate Conception of Mary on Dec. 8th from Coimbra, the
solemn observance was extended to the whole country. Considering the prolonged
and bewildering medieval controversy on the subject of the Immaculate
Conception, and keeping in mind that it was during Elizabeth's lifetime that the
Franciscan "Subtle Doctor" Duns Scotus (1266-1308) answered the
theological difficulties of this doctrine, we may conclude that Queen Elizabeth
was well-informed as to major happenings in academic circles abroad. (Pope John
Paul II beatified Duns Scotus on March 20th, 1993.)
       </p>
       <p>
        While Elizabeth's mastery of languages, and singing, may be explained by the
careful education she received as a young child, more difficult to explain is
her remarkable understanding of engineering and architecture. A number of
buildings were erected under her direct supervision-a convent to house the
Poor Clare nuns, a house for herself next to the convent, a hospice for the aged
poor, a hospital, an orphanage for foundlings and other needy newborns, and
churches that, although dilapidated in some cases, are still standing. She
drafted the sketches herself, and managed the day-to-day progress of the
projects. Twentieth-century scholars have identified the buildings that date
back to Elizabeth by their common architectural features, and have concluded
that she developed her own style. It has been given a name, the &lt;isabeline&gt;
(from Isabel) style of architecture.
       </p>
       <b>
        <font size="4">
         <p>
          Flowers And Gold Coins
         </p>
        </font>
       </b>
       <p>
        Elizabeth paid regular visits to the construction sites, to clarify or
correct the difficult points of her drawings. The men listened to her in rapt
attention, amazed at the extent of her knowledge, that 14thcentury book says.
From Elizabeth's particular involvement in the building trade, a charming legend
was born.
       </p>
       <p>
        The queen had a dream one night in which God asked her to build a church
dedicated to the Holy Spirit. The next morning, she had one of her chaplains
celebrate Mass, and while attending the Holy Sacrifice she received further
clarification.
       </p>
       <p>
        She ordered a construction crew to be assembled and brought to her.
       </p>
       <p>
        She told them of the plan, and specified the site for the church. The workmen
went to the location, and could not believe their eyes: The foundation was
already poured, and the sketches for the church were waiting for them. The men
went to work and, as usual, the queen paid regular visits.
       </p>
       <p>
        One day, while Elizabeth was supervising the work, a girl walked up to her to
offer an armful of flowers. The queen took them and distributed them, one by
one, to each workman:
       </p>
       <p>
        "Let us see if today you will work hard and well for this pay," she
quipped.
       </p>
       <p>
        Each worker graciously accepted his flower, and reverently put it in his
satchel. When the day's work was done, each man found not a flower in his
satchel, but a gold coin.
       </p>
       <p>
        Elizabeth ran out of cash before the church was completed, and was troubled.
Unexpectedly, she received a visit from her husband, who told her to proceed
with all due speed because he would make available from his own resources
whatever she might need.
       </p>
       <p>
        Elizabeth's biographers cannot verify the story of the gold coins, nor any
other mysterious detail of this legend. It seems certain, however, that a Church
of the Holy Spirit was completed, and inaugurated with great solemnity, during
the reign of Dinis and Elizabeth. The royal couple created a Confraternity of
the Holy Spirit at the time.
       </p>
       <p>
        Despite Dinis' infidelity, Elizabeth knew the inner, God-fearing man. Indeed,
he was the first Portuguese king to introduce the custom of general prayer, at
canonic hours, in his residence, and it was on his initiative that a permanent
chapel was installed in the palace where Mass could be celebrated regularly.
       </p>
       <b>
        <font size="4">
         <p>
          A Divided Household
         </p>
        </font>
       </b>
       <p>
        Elizabeth remained Dinis' tender and loyal wife, and she obediently acceded
to his will, even when he asked of her the utmost that any man could request of
his wife: that she take into her care, and tutor, his illegitimate children. He
admired her intellect, and rightly judged that no one better could be found to
teach his children. He also judged rightly that Elizabeth's superior virtues
would prevent her from turning her back on a call to do the heroic. Elizabeth
saw God in the other, and the other encompassed her husband's illegitimate
children.
       </p>
       <p>
        But a far heavier cross awaited Elizabeth. As the children, legitimate and
illegitimate, grew into adulthood, the peace of the realm disintegrated. The
perpetrator was her own beloved son, Afonso, the heir. He was morbidly jealous
of one of his half-brothers whom, he perceived, the father doted on, and chafed
at having to wait for the throne. So Afonso led a revolt against his own father.
       </p>
       <p>
        Civil war became imminent, several times, as Afonso allied himself with
certain elements of the Spanish kingdom of Castile, who were only too willing to
help him overthrow his father. The threat was real, and it fell to Elizabeth to
mediate peace between the two men closest to her heart, husband and son, each of
whom led an army.
       </p>
       <p>
        Astoundingly, the first time that she intervened to help her son escape the
consequences of his rebellion, Dinis exiled her to the fortified city' of
Alenquer, forbidding her to leave the city walls. It must be said, in fairness
to King Dinis, that he had been misinformed by evil tongues and had been led to
believe that Elizabeth herself had counseled Afonso to rebel. Political intrigue
has always been one of the hazards of court life.
       </p>
       <p>
        Although innocent, Elizabeth obediently accepted the confinement. But upon
receiving offers of assistance from a number of noblemen, who professed outrage
at the injustice she had suffered and offered to rescue her, she answered them
as their queen: "My primary obligation, and the obligation of all the
vassals, is to obey the commands of the king, our lord."
       </p>
       <b>
        <font size="4">
         <p>
          The Angel Of Peace
         </p>
        </font>
       </b>
       <p>
        Unjust sequestration is a well-known feature of the lives of most great
saints, and Elizabeth was no exception. She stayed in exile until news came that
the hostilities between her husband and son had heated anew. Afonso had secured
additional military help from Castile, and his father had responded by greatly
reinforcing his own army. The whole country-as well as her family-was in
peril, so Elizabeth did abandon then her place of exile and rode for days, to
mediate peace between the two men bent on destruction.
       </p>
       <p>
        It was a scene that, with a number of variants, was repeated over and over:
agreements made, agreements broken, armies on the move, and an exhausted,
heartbroken Elizabeth riding out to valiantly face the warring parties,
imploring, negotiating. Her biographers have dubbed her the "Angel of
Peace." When he was on his deathbed, King Dinis called Afonso to his side,
and entrusted Elizabeth to his care:
       </p>
       <p>
        "Look after your mother and my lady, the queen, for she remains alone.
Stand by her, as is your duty.... Think that having given you life, and for the
many tears you have cost her, she is twice your mother."
       </p>
       <p>
        In his peculiar way, Dinis held his queen in the highest esteem. He named her
executor of his last will and testament, in which he made provision for the
payment of all his debts, "having in mind God's Judgment," and for the
disposition of castles, towns, and endowments to churches. But the king's
highest praise of his wife is found, perhaps, in one of his poems:
       </p>
       <p>
        Seeing as God made you without peer
        <br/>
        In goodness of heart and goodness of speech,
        <br/>
        Nor is your equal anywhere to be found,
        <br/>
        My love, my lady, I hereby tell you:
        <br/>
        Had God desired to ordain it so,
        <br/>
        You would have made a great king.
       </p>
       <b>
        <font size="4">
         <p>
          A Kingdom Of Justice
         </p>
        </font>
       </b>
       <p>
        Dinis, one of Portugal's best-loved monarchs, died in February, 1325 at the
age of 63, but not without taking leave also of his bastard children. The queen,
who nursed him herself and stayed by his bedside day and night, led them to
their dying father for his last blessing. Upon Dinis' death, Elizabeth removed
her court dress and thereafter refused to wear anything but the habit of the
Franciscan Tertiary order. She took up residence next to the convent of the Poor
Clares, which she had founded and subsidized. It was then that the widowed queen
founded a hospital near the convent, and named it after St. Elizabeth of
Hungary. On a daily basis, Elizabeth worked in caring for the sick, often
choosing for herself the most distasteful tasks.
       </p>
       <p>
        Queen Elizabeth outlived her husband by 12 years. Mourning his death
intensely, she said, "I have always beseeched our Lord to kindly spare me
the bitterness of surviving the king, my lord. I have wished him a long life,
for the good and well-being of the people."
       </p>
       <p>
        Elizabeth always looked beyond herself, for she loved her subjects dearly.
And she knew that they had also greatly loved her husband, who had taken radical
measures to improve their lot. He had transformed agriculture, worked at
increasing literacy, and, like Elizabeth, was moved by a deep need to see that
justice prevailed in his kingdom. A striking feature of written accounts of
Dinis' and Elizabeth's reign, which even the most casual reader of medieval
histories cannot fail to notice, is the total absence of that "off with
their heads" syndrome of medieval monarchic power, so prevalent elsewhere.
When Dinis issued in 1309 a charter of privileges to the university he had
founded, he began with a statement of intent: He officially established his
university, he wrote, in order that his kingdom should be not only adorned with
arms, but also armed with just and fair laws.
       </p>
       <p>
        Elizabeth was of one mind with her husband, in matters of justice for her
subjects. Recent researches have turned up five official documents issued by the
Papal See at Avignon, attending to Elizabeth's written requests for the
appointments of persons with law credentials to important posts. Scholars wonder
how many other such documents lie still buried in archives.
       </p>
       <p>
        Nor did she abide by the belief that rank has privileges and excuses
injustices. Still preserved is an interesting letter that Elizabeth wrote to her
brother, the king of Aragon, demanding in no uncertain terms that he pay a large
debt in full. The amount was owed to a certain woman who, understandably, shrank
at the prospect of seeking satisfaction from a king. "Know ye, my
brother," starts Elizabeth, bypassing the niceties of usual greetings and
proceeding directly to inform him, in harsh language, that the letter-bearer
will not leave Aragon without the full amount in cash, and placing a time limit
on her demand.
       </p>
       <p>
        St. Elizabeth brooked no injustice, provided that reparation was within her
means. "God made me queen so that I may serve others," was the way she
used to cut short any attempts to laud her generosity.
       </p>
       <b>
        <font size="4">
         <p>
          A Wounded Leper
         </p>
        </font>
       </b>
       <p>
        Some of Elizabeth's acts of charity are so sublime that one almost shies away
from mentioning them, for fear of trespassing on the sacred. The following case
is related in the above-mentioned 14th-century book, where it is stated that it
was attested to under oath, before the bishop of Lisbon.
       </p>
       <p>
        It was Good Friday and Queen Elizabeth, as was her custom on that day, had a
number of lepers brought to her in private, through a secluded door. She used to
do this because the law forbade them to approach her residence, for fear of
contagion. But Elizabeth saw God in the lepers, too.
       </p>
       <p>
        After serving them a meal, the queen washed them with her own hands, bandaged
their wounds, and replaced their rags with clean clothes. Then, having filled
their purses, she dismissed them. But one of those unfortunates was in such a
state of deterioration that, unable to keep pace with the group, he became
disoriented and ended up at the main entrance. The doorkeeper, who knew nothing
of his queen's secret works of mercy, yelled at the sick man and hit him on the
head with a stick.
       </p>
       <p>
        One of the queen's ladies-in-waiting was watching from a window and reported
the incident to Elizabeth, informing her that the wounded man was bleeding
profusely. Elizabeth immediately took measures to have the leper removed to a
secluded room, where she managed to attend to him. She washed the gash on his
skull, and applied egg-white before bandaging it. When, the next day, the leper
announced that he had no more pain, that the wound was closed and healed, the
rumor spread that the queen performed miracles.
       </p>
       <p>
        Doctors have commented on this episode. If St. Elizabeth's touch was not
miraculous, her knowledge of medicine certainly appears to have been. She lived
in an age when healing practices consisted, essentially, in astrological
prognostications. And yet, now that we know about the protein and fibrinogenic
components in egg-white, it can be said that, in the absence of all other aids,
it is the most effective remedy for a bleeding wound.
       </p>
       <p>
        In 1779, the Portuguese Academy of Sciences chose St. Elizabeth as its patron
saint.
       </p>
       <b>
        <font size="4">
         <p>
          An Incorruptible
         </p>
        </font>
       </b>
       <p>
        Queen Elizabeth died on July 4th, 1336. She was 65 years of age, perhaps
somewhat older, and had incorporated into her passage through this earth
prayers, sacrifices, interventions for peace among monarchs, acts of worship,
and works of mercy too numerous to mention in this brief piece. Almost three
centuries after her death, His Holiness Pope Urban VIII inexplicably broke his
reported vow that there would be no canonizations during his Pontificate: He
canonized St. Elizabeth of Portugal on Holy Trinity Sunday, May 25th, 1625.
       </p>
       <p>
        Little has been written in English about St. Elizabeth, yet she is a timeless
role model for women everywhere. Because she moved with equal ease among
powerful rulers and among the least of the least, and in passing blessed them
all, because there appears to have been no task that fell outside the realm of
her competence and she won over situations that would paralyze most men and
women, her significance is universal.
       </p>
       <p>
        We ought not to forget her, and God has ensured this in the land she blessed,
where her body remains incorrupt. Reposing in the Church of St. Clare at Coimbra,
her elaborate coffin has been opened several times through the centuries as
recently as 1912. The teams of examiners, invariably composed of doctors and
Church officials, consistently reported that St. Elizabeth remains intact, as
beautiful and serene as if she merely slept.
       </p>
       <hr/>
       <p>
        This article was taken from the July 4, 1996 issue of "The
Wanderer," 201 Ohio Street, St. Paul, MN 55107, 612-224-5733.
       </p>
       <hr/>
      </td>
     </tr>
     <tr>
      <td>
       <p align="center">
        Provided Courtesy of:
        <br/>
        Eternal Word Television Network
        <br/>
        5817 Old Leeds Road
        <br/>
        Irondale, AL 35210
        <br/>
        <a href="http://www.ewtn.com">
         www.ewtn.com
        </a>
       </p>
      </td>
     </tr>
    </table>
   </center>
  </div>
 </body>
</html>
`
const replacements = {
}
Object.entries(replacements).forEach(([key, value]) => {
htmlAsString = htmlAsString.replace(key, value)
})
export default <div dangerouslySetInnerHTML={{__html: htmlAsString}}/>