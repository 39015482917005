import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Button } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

const LINKS = [
  {
    title: "Madeira Island, the Concelhos (municipalities) and Freguesias (parishes)",
    type: "San Pedro Association",
    navigate : "../legacy/smadeira.htm",
    thumbnail: require("../assets/MunicipalitiesAndParishes.gif")
  },
  {
    title: "Madeira Islands, Portugal Hotel Deals",
    type: "Trip Advisor",
    url: "https://www.tripadvisor.com/SmartDeals-g189165-Madeira_Islands-Hotel-Deals.html",
    thumbnail: require("../assets/hotel-the-cliff-bay.jpg")
  },
  {
    title: "Madeira Levada Walks",
    type: "Madeira Explorers",
    url: "https://www.madeira-levada-walks.com/en/",
    thumbnail: require("../assets/MadeiraLevadaWalks.png")
  },
  {
    title: "History of the Island",
    type: "San Pedro Association",
    navigate : "../legacy/shist.htm",
    thumbnail: require("../assets/MadeiraIslandHistory.jpg")
  },
  {
    title: "ELIZABETH OF PORTUGAL: 'FOR, IN HER IS A SPIRIT INTELLIGENT, HOLY, UNIQUE'",
    type: "San Pedro Association",
    url: "../legacy/isabel.htm",
    thumbnail: require("../assets/ElizabethOfPortugal.jpg")
  }
]

function LinkCard({ link }) {
  const navigate = useNavigate();
  const mediaWidth = 250
  const onClick = () => {
    if (link.url) {
      window.open(link.url)
    } else if (link.navigate) {
      window.location.href = link.navigate
    }
  }
  return (
    <Button style={{ padding: 0 }} onClick={onClick}>
      <Card sx={{ display: 'flex', width: 800, borderRadius: 20 }} elevation={6}>
        <CardMedia
          component="img"
          sx={{ width: mediaWidth, maxHeight: mediaWidth }}
          image={link.thumbnail}
          alt={link.title}
        />
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", padding: "10px" }}>
          <Typography variant="h6">
            {link.title}
          </Typography>
          <br />
          <Typography variant="body1">
            {link.type}
          </Typography>
        </Box>
      </Card>
    </Button>
  );
}

export default function LinksPage() {
  return (
    <div className="faded-background" style={{
      "--b": `url(${require("../assets/MadeiraMap.jpg")})`,
      "--o": 0.5,
      display: "flex",
      flexDirection: "column",
      paddingTop: "20px",
      gap: "50px",
      paddingBottom: "20px",
      alignItems: "center",
      justifyContent: "center"
    }}>
      {LINKS.map(link => <LinkCard link={link} />)}
    </div>
  )
}