import logo from './logo.svg';
import './App.css';
import HomePage from './Components/HomePage';
import AboutUsPage from './Components/AboutUsPage';
import ContactUsPage from './Components/ContactUsPage';
import GalleryPage from './Components/GalleryPage';
import CalendarPage from './Components/CalendarPage';
import GetInvolvedPage from './Components/GetInvolvedPage';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import LinksPage from './Components/LinksPage';
import TopBar from './Components/TopBar';
import MenuBar from './Components/MenuBar';
import createTheme from './Components/createTheme';
import ParkRentalsPage from './Components/ParkRentalsPage';
import LegacyPage from './Components/LegacyPage';

function Layout() {
  return (
    <div>
      <TopBar />
      <MenuBar />
      <Outlet />
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={createTheme()}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="aboutus" element={<AboutUsPage />} />
              <Route path="contactus" element={<ContactUsPage />} />
              <Route path="events" element={<CalendarPage />} />
              <Route path="gallery" element={<GalleryPage />} />
              <Route path="links" element={<LinksPage />} />
              <Route path="parkrentals" element={<ParkRentalsPage />} />
              <Route path="getinvolved" element={<GetInvolvedPage />} />
              <Route path="legacy/:filename" element={<LegacyPage />} />
              <Route path="*" element={<div>404</div>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
