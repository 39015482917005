import React, { useState } from 'react'
import { AppBar as MuiAppBar, Button, Toolbar, Menu, MenuItem, ThemeProvider, createTheme } from '@mui/material';

const MENU_BUTTONS = [
  {
    href: "/",
    text: "Home",
    anchors: null
  },
  {
    href: "/aboutus",
    text: "About Us",
    anchors: [
      "board-of-directors",
      "history-of-san-pedro",
      "articles-of-incorporation",
      "duties-of-the-board"
    ]
  },
  {
    href: "/contactus",
    text: "Contact Us",
    anchors: null
  },
  {
    href: "/events",
    text: "Calendar",
    anchors: null
  },
  {
    href: "/gallery",
    text: "Gallery",
    anchors: null
  },
  {
    href: "/links",
    text: "Links",
    anchors: null
  },
  {
    href: "/parkrentals",
    text: "Park Rentals",
    anchors: null
  },
  {
    href: "/getinvolved",
    text: "Friends Of San Pedro",
    anchors: null
  }
]

function ToolbarButton({ href, text }) {
  const [style, setStyle] = useState({})

  const onMouseLeave = (event) => {
    setStyle({})
  }
  const onMouseEnter = (event) => {
    setStyle({
      borderBottom: "5px solid white",
      marginBottom: "-5px"
    })
  }
  return (
    <div
      style={style}
    >
      <Button
        color="inherit" href={href}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {text}
      </Button>
    </div>
  )
}

export default function MenuBar() {

  return (
    <MuiAppBar
      id="menu-bar" 
      position="sticky" 
    >
      <Toolbar style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "10%",
        paddingRight: "10%"
      }}>
        {
          MENU_BUTTONS.map(button => <ToolbarButton key={button.href} href={button.href} text={button.text} />)
        }
      </Toolbar>
    </MuiAppBar>
  )

}
