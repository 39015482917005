import react, { useState, useEffect } from 'react'

import { CircularProgress, TableContainer, Paper, Table, TableRow, TableCell, TableBody, Typography, useTheme, IconButton } from '@mui/material'
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SendIcon from '@mui/icons-material/Send';
import { fetchGoogleDriveFolder, googleDriveFileUrl } from './googleDriveApi'
import BackgroundImage, { implyAspectRatio } from './BackgroundImage'

const BOARD_MEMBERS = [
  {
    name: "Cathy Ingram-Kelly",
    email: "",
    title: "President",
    coor: [0.5053177147308188, 0.36032716160345135]
  },
  {
    name: "Steve Freitas",
    email: "",
    title: "Vice-President",
    coor: [0.6567901974295216, 0.2941758044220744]
  },
  {
    name: "Valerie O'Connor",
    email: "",
    title: "Secretary",
    coor: [0.35576260523083375, 0.37183174546108216]
  },
  {
    name: "Dennis Freitas",
    email: "",
    title: "Treasurer",
    coor: [0.7047245273974655, 0.3430702858170052]
  },
  {
    name: "Rebecca Weill",
    email: "sanpedrobryte@gmail.com",
    title: "\"Friends of San Pedro\"",
    coor: [0.29440666287186557, 0.5070106057882438]
  },
  {
    name: "Larry Freitas",
    email: "sanpedroparkrentals@gmail.com",
    title: "Park Rentals/Coordination",
    coor: [0.4554660115641571, 0.3056803882797052]
  },
  {
    name: "Angie Ramos",
    email: "",
    title: "Chamber of Commerce",
    coor: [0.45354863836543935, 0.49838216789502066]
  },
  {
    name: "Violet Arizmendez",
    email: "",
    title: "Social Media",
    coor: null
  },
  {
    name: "Silvestre Gilmete",
    email: "",
    title: "Cabo Verde Liaison/Veteran's Day Parade",
    coor: [0.32891938044878516, 0.2539097609203667]
  },
  {
    name: "Christina Velez",
    email: "",
    title: "Holy Cross Church liaison",
    coor: [0.6318643458461907, 0.5070106057882438]
  },
  {
    name: "Karin McClelland",
    email: "",
    title: "",
    coor: [0.6088558674615776, 0.33156570195937446]
  }
]

function BoardImageOverlay({ width, aspectRatio, selectedMember }) {
  const height = `calc(${width} * ${aspectRatio})`
  const Ring = ({ member }) => {
    console.log(member)
    let cx, cy;
    BOARD_MEMBERS.forEach(m => {
      if (m.name == member.name) {
        [cx, cy] = m.coor
      }
    })
    cx = `calc(${width} * ${cx})`
    cy = `calc(${height} * ${cy})`
    const r = `calc(${width} * 0.05)`
    const color = member.color
    return <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth="5" fill="none" />
  }

  return (
    <div
      id="board-image-overlay"
      style={{
        position: "absolute",
        width: width,
        height: height
      }}
      onClick={(event) => {
        const divBox = document.getElementById("board-image-overlay").getBoundingClientRect()
        const deltaX = (event.clientX - divBox.x) / divBox.width
        const deltaY = (event.clientY - divBox.y) / divBox.height
        console.log([deltaX, deltaY])
      }}
    >
      <svg width={width} height={height}>
        {selectedMember && selectedMember.name && <Ring member={selectedMember} />}
      </svg>
    </div>
  )
}

function BoardImage({ selectedMember }) {
  const width = "45vw"
  return (
    <div style={{
      position: "relative"
    }}>
      <BoardImageOverlay selectedMember={selectedMember} width={width} aspectRatio={2 / 3} />
      <BackgroundImage src={require("../assets/BoardOfDirectors.jpg")} width={width} aspectRatio={2 / 3} />
    </div>
  )
}

function BoardTable({ setSelectedMember, selectedMember }) {
  const width = 45

  const isSelected = (member) => {
    return selectedMember && selectedMember.name == member.name
  }
  return (
    <div style={{
      width: `${width}vw`
    }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" padding="none">
          <TableBody>
            {BOARD_MEMBERS.map((row) => (
              <TableRow
                key={row.name}
              >
                <TableCell>
                  <IconButton
                    size="large"
                    color="inherit"
                    style={{
                      margin: "0px 10px",
                    }}
                    disabled={row.coor === null}
                    onClick={() => {
                      isSelected(row) ?
                        setSelectedMember({}) :
                        setSelectedMember({ name: row.name, color: 'red' })
                    }}
                  >
                    <SavedSearchIcon style={isSelected(row) ? { fill: "red" } : {}} />
                  </IconButton>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.email}</TableCell>
                {/* <TableCell>
                  <IconButton
                    size="large"
                    color="inherit"
                    style={{
                      margin: "0px 10px"
                    }}
                  >
                    https://stackoverflow.com/questions/55795125/how-to-send-email-from-my-react-web-application
                    <SendIcon />
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default function BoardOfDirectors() {
  const [selectedMember, setSelectedMember] = useState()
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      paddingLeft: "40px",
      paddingRight: "40px",
      gap: "40px",
      alignItems: "center"
    }}>
      <BoardImage selectedMember={selectedMember} />
      <BoardTable setSelectedMember={setSelectedMember} selectedMember={selectedMember} />
    </div>
  )
}