import { createTheme as muiCreateTheme } from "@mui/material";

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.typography
export default function createMuiTheme() {
  return muiCreateTheme({
    palette: {
      common: {
        white: "#FFFFFF",
        black: "#000000"
      },
      primary: {
        // main: '#0052cc', // pre-existing-blue
        main: "#263E78",  // madeira blue
        light: "#1181EC",  // neon blue
        dark: "#0C00AA",  // neon blue
        contrastText: "#FFFFFF",  //white
      },
      secondary: {
        main: "#FFD700",  // madeira yellow
        contrastText: "#263E78"
      }
    },
    typography: {
      fontFamily: [
        // "serif",  // works
        // "sans-serif",  // works
        // "Perpetua",  // works
        "Times New Roman",  // works
        // "Courier New",  // works
      ].join(",")
      // fontFamily: [
      //   '-apple-system',
      //   'BlinkMacSystemFont',
      //   '"Segoe UI"',
      //   'Roboto',
      //   '"Helvetica Neue"',
      //   'Arial',
      //   'sans-serif',
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(','),
    }
  })
};