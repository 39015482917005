let htmlAsString = `<html>
 <head>
 </head>
 <body>
  <title>
   madeira history
  </title>
  <style type="text/css">
   <!--

p {  font-family: Arial, Helvetica, sans-serif; font-size: 14px}

h1 {  font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: bold}

h2 {  font-family: Arial, Helvetica, sans-serif; font-size: 16px; font-weight: bold}

h3 {  font-family: Arial, Helvetica, sans-serif; font-size: 24px; font-weight: bold}



-->
  </style>
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
   <tr>
    <td bgcolor="#ffff80">
     <br/>
     <h3>
      <font color="#0000ff">
      </font>
      <center>
       <font face="comic sans ms">
        Sao Pedro Association
       </font>
      </center>
     </h3>
    </td>
   </tr>
  </table>
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
   <tr>
    <td bgcolor="#9999CC" width="100%">
    </td>
    <td width="0%">
    </td>
   </tr>
  </table>
  <table border="0" cellpadding="0" cellspacing="0" width="760">
   <tr>
    <td bgcolor="#ffff80" valign="top" width="110">
     <table border="0" cellpadding="0" cellspacing="5" width="100%">
     </table>
    </td>
    <td bgcolor="#9999CC">
    </td>
    <td valign="top" width="650">
     <table border="0" cellpadding="0" cellspacing="5" width="100%">
      <td valign="top" width="650">
       <table border="0" cellpadding="0" cellspacing="5" width="100%">
        <tr>
         <td>
          <h1>
           <b>
            <br/>
            Madeira Island History
           </b>
          </h1>
          <hr noshade="" size="1"/>
          <br/>
          <center>
           <table>
            <td>
             <p>
             </p>
             <h2>
              BEGINNINGS
             </h2>
             <p>
              <b>
               One of the beauties of Madeira, writes Sacheverell Sitwell, Is that, comparatively, it has no past.... It was a virgin island. There were no aboriginal inhabitants to exterminate. Recorded history tells us that humans first set foot on Madeira in the early 15th century. Legends tell another story; Ptolemy wrote of an island in the Atlantic called Erythria, the "Red island", because of the dye that was extracted there. (Many centuries later, Madeira would still be famous for its "Dragon's Blood", a resin used to color varnishes. Pliny, the 1st-century AD Roman writer, spokes of the "Purple islands", also named for their dye, that were discovered by sailors blown off course during a voyage to the Fortunate isles (his name for the Canaries). If the Romans were there, it is likely that the Phoenicians had been there before them. Certainly the Moors knew about the archipelago by the time they invaded the Iberian Peninsula in the 8th century. They called the group El Ghanam meaning cattle (possibly a reference to goats or sheep breeding on the islands).
               <br/>
               <br/>
               Early Christians believed in a Holy Island off the coast of Africa, inhabited by St.Brendan and a colony of saint. It would disappear into mist whenever sinners approached. Sailors in the 15th century saw the clouds that continually enveloped Madeira's mountains, and were sure they were looking at the Mouth of Hell. Later they believed that the island was part of the lost continent of Atlantis, or the Hesperides -the islands which, according to the scholar Dr.Lempriere, were once residence of the Amazons, a celebrated place or garden abounding with fruits of the most delicious kinds. It was the Twelfth Labour of Hercules to fetch golden apples from this island at the world's end, wresting them from the dragon and the "daughters of the evening" (Hesperides) that guarded the tree.
               <br/>
               <br/>
               Legend begins to overlap history with the tale of Robert Machim and Anne d'Arfet. Robert was a minor English nobleman during the reign of Edward III (1327-77). He traded with Genoa, Pisa and Venice in his ship La Welyfare, but was banished from the kingdom. This much is fact: his banishment is registered in the Rolls of Parliament. The rest of the story has almost as many versions as chroniclers. Possibly the most reliable is the one that comes to us through the 15th century writer and explorer Francisco Alcaforado, who probably had it on hearsay from sailors. Robert fell in love with Anne d'Arfet. She was from a higher rung of the aristocracy, and when her family found out about the affair they not only forbade her from seeing Robert, but also forced her to marry someone of equal status. Her parents must have had some idea of Machin's daring, as they got a warrant from King Edward to have him imprisoned until the wedding ceremony was over. As soon as he was free of the dungeon, Machim had a message smuggled to Anne, and the two eloped on his boat. They set off from Bristol, he headed for the continent, but a storm blew them out to sea. After a fortnight spent bobbing, lost, on the ocean, they came to a heavily wooded island and weighed anchor in a small bay - where Machico is today. Machim went ashore to explore the island, but when he got back he found that his crew had absconded with the ship, leaving just Anne and a page on the shore. The trauma proved too much for Anne, who died three days later. The crew reached the Barbary Coast, where, for their sins, they were captured by Moors and sold into slavery. Robert Machim buried his lover beneath a large cedar tree, putting a simple wooden cross above her grave, together with a letter telling the tale and

asking anyone who discovered the grave to build a chapel in their memory.
              </b>
             </p>
             <h2>
              THE REDISCOVERY OF MADEIRA
             </h2>
             <p>
              Some chroniclers leave Machim to die on the island too. The story was brought by some crewmembers of Machim ship to the Portuguese adventure Joao Goncalves known as "Zarco" (which means "the squinter", or "One-eyed"). Joao Goncalves Zarco (he later took his nickname as a surname) was a fierce fighter and a gallant knight. He had lost an eye in battle against the Moors at Ceuta, fighting for Portugal under the Infante Dom Henrique -who is better known as Prince Henry the Navigator. It is quite likely that the Infante had some idea of the existence of Madeira. In the Laurentian Library in Florence is a map, known as the Medici Map and dated 1351, that clearly marks three islands off the African coast, naming them as Porto Santa, Deserta and Isola de Lolegname (Italian for Island of Woods. Some believes that this is a corruption of the Moorish El Ghanam). The Prince who had Genoese navigators in his employ and who had spoken to hundreds of seamen must have had some 

inkling that the islands were there.
              <br/>
              <br/>
              Then in 1418 Zarco and a fellow navigator Tristao Vaz were blown off the coast of Guinea, which Prince Henry had sent them to explore. They ended up on Porto Santo. Beyond Porto Santo they found that "there hung over the sea a thick impenetrable darkness, which was guarded by a strange noise". After a few days rest they made a beeline for Portugal to tell the Infante of their discovery. The sailors were sure that the immobile mass of cloud they had seen was smoke from the mouth of Hell. Maybe Zarco had spoken to one of the sailors of Machim ship and knew better. More likely, it was Prince Henry who put two and two together. He lost little time in dispatching a ship under Bartolomeu Perestrelo to colonize Porto Santo and two more, commanded by the intrepid Zarco and Vaz, to explore further. The flotilla arrived at Porto Santo late in the summer of 1419 and early the following year Zarco and Vaz set off to investigate the distant smoke. They found a mountainous island a so thickly covered in tree that was perpetually shrouded in cloud and mist. Zarco named it "Madeira" (Portuguese for wood), either in response of the forest, or as a translation of the Italian name that Prince Henry had already made known to him.
             </p>
             <h2>
              THE FIRST SETTLEMENT
             </h2>
             <p>
              Zarco was made "captain" of the southwestern part of the island, and Vaz (who later adopted his wife's surname, Teixeira) had jurisdiction over the northeastern part. The dividing line ran from Ponta do Tristao in the north to Ponta da Oliveira near Canico. Vas's capital was at Machico, where the settlers had found the grave of Anne d'Arfet and built a chapel. Zarco was the senior governor. He set up house in Camara de Lobos, but very soon moved to the more favorable location of Funchal. His descendants were to rule in an unbroken line until the Spanish occupation in 1580. The old squinter himself lived to the impressive age of eighty. The story goes that just a year or two before his death three French pirate ships sailed into Funchal bay. Zarco mounted his grey horse, took up his famous two-handed sword and (discreetly propped up by lieutenant on either side) took up position on the beach in front of his men. Such was the old warrior's reputation that the corsairs turned and fled.
              <br/>
              <br/>
              In 1425 Dom Joao officially made Madeira a full province of Portugal, handing it as a gift to the Infante Dom Henrique. Colonization then began in earnest. Three strapping young nobles were sent to marry Zarco's daughters, and members of some of Portugals' grandest families came along to help swell the population. Madeiran family names such as
              <b>
               Bettencourt, Freitas, Almeida and Ornelas
              </b>
              date back to these aristocratic settlers. The first children born on the island were twins, the offspring of one Goncalo Ayres Ferreira. They were appropriately named Adam and Eve.
             </p>
             <h2>
              FAME AND FORTUNE
             </h2>
             <p>
              One of Zarco's first acts had been to begin clearing the island for cultivation. The enormous prehistoric trees proved too much for the handful of early inhabitants, who started fires to speed 

things up a bit. This is supposed to have unleash an inferno, which drove the settlers into the sea for two days, and was still smoldering in parts of the island seven years 

later. Tragically, the giant forest were destroyed, but the ash they left behind combined with the island?s volcanic crust to make a lusciously rich soil. Prince Henry had ordered vines from Crete and sugar cane from Sicily to be grown on the island, 

hoping to cut in on market monopolized by the Venetians and Genoese. He was successful. Both crops flourished enormously, and despite the fact that the influx of Madeiran sugar caused the market price to drop by 50 per cent by the year 1500, many people made their fortunes. Funchal became a properous capital -Portugal's third city after Lisbon and Porto. Wealthier landowners and merchants built grand houses in town, leaving the hard work to peasants and slaves. Only Pirate raids threatened the good life. Shipfuls of bandits would swoop in with alarming regularity and make off with rich spoils. Porto Santo is the more exposed island, had the worst of it. This, and the fact that rabbits introduced by Perestrelo and his colonists had nibbled the island bare, caused many settlers to leave Porto Santo for Madeira. In 1566 the French pirate Bertrand de Montluc fell on Funchal with a thousand men. They plundered and massacred for 15 days, then made off with 1.500.000 gold pieces before help could be fetched from the mainland. But Montluc's glee didn't long last -within days he was dead as the result of a wound he had received during the raid.
             </p>
             <h2>
              THE SPANISH OCCUPATION
             </h2>
             <p>
              In 1580 Philip II of Spain laid claim to the Portuguese crown. Portugal's coffers were empty after a disastrous campaign against Morocco. The king, Dom Sebastian, had been killed, and the line of succession wasn't clear. The Jesuits and wealthy Portuguese backed Philip's claim, but it was the promise of Spanish silver that tipped the scales in his favour. In 1582 a new governor arrived on Madeira and for the first time united the two halves of the island into a single administrative entity. The Spanish modernized Madeira's fortifications, but saw the local wine industry as a threat to its own. They restricted vine growing and winemaking severely, and exports dropped to almost nothing. The sugar and timber trades, which weren't in competition with Spanish interests, continued to boom; the Spanish Armada that threatened to upset Sir Francis Drake's game of bowls was made largely of Madeiran wood.
             </p>
             <h2>
              INDEPENDENCE AND MONOPOLIES
             </h2>
             <p>
              Spanish rule lasted until 1640, when a Catalan rebellion distracted the Castilian kings and gave the Duke of Braganca, a member of the old Portuguese ruling house of Avis, a chance to seize back the throne. He was crowned King Joao IV, but fighting went on until 1665, after the battle of Vila Vicosa, the Spanish were finally defeated and Portugal became fully independent. By this time Dom Afonso VI (1656-67) had succeeded his father as king, but he was quite mad and so his mother Dona Luisa de Braganca ruled Portugal. She was keen to strengthen ties with England, as France and Spain were showing 

disturbing signs of making peace with each other. Dona Luisa offered her daughter Catherine of Braganca as a wife to the newly restored King Charles II. Louis XIV of France had already turned her down, and so Dona Luisa upped the stakes a bit. Catherine came with double the usual 

royal dowry: solid weight in silver and gold, a concession for trade with Brazil, two million cruzados, Bombay, Tangier and -it is said- Madeira. But the scribe who was writing all this down was, reputedly, a Madeirense who couldn't bring himself to include his island on the list. He persuaded Dona Luisa to keep Madeira back as an ultimate lure, should King Charles fail to bite. Catherine traveled to England with a secret document, which adds Madeira to her dowry, but she didn't need it. The merry Monarch jumped at the opportunity of a source of funds for his lavish lifestyle. In return he agreed to defend Portugal as if it were England itself. Madeiran trade went from strength to strength. King Joao IV had ordered Portuguese ships to call in on the island and take on wine for the colonists in Brazil, thus laying the foundations for the modern Madeira wine trade. Eventually the island became the chief stopover for all ships wanting to stock up on supplies before beginning the long haul across the Atlantic. When, in 1665, King Charles issued his ordinance forbidding exports to "English Plantations oversea", save for goods leaving English ports in "English bottoms", he excluded Madeira from the ban. This meant that local wine merchants had what amounted to a monopoly over trade with America and the West Indies. Madeiran wine became one of the most popular drinks in the New World, and a strong community of British merchants grew up on Madeira. They cornered the wine market, expanded into sugar and other trades and were soon leading players in the island's economy.
             </p>
             <h2>
              THE BRITISH
             </h2>
             <p>
              From the end of the 17th century onwards, the British played such an integral role in island affairs that many of them seemed to regard Madeira as a colony rather than foreign territory. Though the authorities were often concerned that the British owned too much property on the island, and had their fingers (if not their fists) in too many local pies, the relationship between the two communities has, on the whole, been cordial. Feelings only became a little strained when British troops were garrisoned on the island, and when an over-zealous pastor tried to convert local Portuguese to Protestantism and there was very nearly a nasty moment when Captain Cook visited the island in 1768. The famous explorer, then on his first voyage in the Endeavour, battered the ford of the Loo Rock...by way of resenting an affront that had been offered to the British flag. Exactly what the nature of the insult is we do not know, as the account was suppressed from official reports. Later, though, Cook made up for his petulance by going ashore and planting a tulip tree. The tree survived until 1963, when it blew down in a storm.
             </p>
             <h2>
              NAPOLEAN AND THE BRITISH OCCUPATIONS
             </h2>
             <p>
              British forces twice occupied Madeira during the Napoleonic Wars -from July 1801 to January 1802, and again from December 1807 to October 1814. They were friendly occupations, aimed at protecting the island from the French, but the first one caused a diplomatic rumpus as no one had informed the Portuguese governor that he was about to be superseded. General William Carr Beresford, who was already quite a hero after campaigns in Egypt, South Africa and the Argentine, commanded the second occupation.
              <br/>
              <br/>
              Napoleon had tried to persuade the Portuguese to co-operate with the naval blockade of Britain. Portugal refused, and in 1807 French troops marched on Lisbon. The British whisked the Portuguese royal family away to safety in Brazil. Generals Beresford and Wellesley stayed on to deal with the French. On Christmas Eve Beresford arrived in Funchal Bay with a fleet of 24 ships, but soon realized that the island wasn't in need of quite so much protection. Beresford left with half his garrison in 1808, though the rest of the troops stayed on until peace was finally made with France -and contributed noticeably to the island's gene pool. Even today you see the odd
              <b>
               blond or ginger mop
              </b>
              bobbing along in an otherwise swarthy school of crocodile.
              <br/>
              <br/>
              Napolean himself called on Madeira in 1815, on his way to exile in St.Helena. He found an unexpected admirer in the then British consul, Henry Veitch. Veitch sold Boney some pipes of excellent wine and got into hot water by addressing the ex-Emperor as "Your Majesty". He kept the gold  he had received in payment, and buried them behind the foundation stone of the new English Church.
             </p>
             <h2>
              THE BRITISH FACTORY
             </h2>
             <p>
              During the course of the 18th century British merchants came to control the wine trade almost entirely. When the dreaded diseases oidium and the phylloxera struck in the second half of the 19th century, destroying nearly all the vines, many merchants moved over to sugar-refining, and soon the Brits had the grip on that too. Some time during the 18th century the merchants established the British Factory. This was the equivalent of a Chamber of Commerce made up of the senior partners of the island's leading British firms, and based on the feitorias that the Portuguese had set up in the 15th and 16th centuries to guard and administer their trading posts abroad. For decades the Factory held sway over the Madeiran British, acting almost like a small colonial government. The factors levied a tax on wine exports and built and financed a church, hospital and cemetery. They were a force not to be crossed (as young upstart traders soon learned), and adjudicated in (though sometimes propelled) the feuds that local families waged against each other. The Factory also acted as an agent for British merchants, enabling them to buy collectively, and so had enormous economic clout on the island. It was finally wound up in 1838, during a depression in the wine trade. The remaining factors started The English Rooms, an exclusive club on the seafront in Funchal. Later, as membership thinned out, they had to lower their standards and accept partners from less powerful firm's -and even managers- into the fold, and eventually the club closed down altogether.
             </p>
             <h2>
              MIGUELITES AND LIBERALS
             </h2>
             <p>
              Even after the defeat of the French in 1814, the Portuguese king Dom Joao VI (1792-1826) remained in Brazil with his family and Beresford stayed on in Lisbon as marshal of the Portuguese army, But the army staged a coup, set up a Cortes (parliament) elected by universal male suffrage, and devised a new constitution. Dom Joao returned from Brazil in 1821, content to accept the restricted powers that this constitution imposed. But his younger son, Dom Miguel, had other ideas. When Dom Joao died in 1826, his elder son and heir Dom Pedro IV was still living in Brazil, having been made Emperor of what was by then a recently independent territory. Dom Miguel grabbed the opportunity to declare himself absolute monarch of Portugal and began a crackdown on supporters of the new constitution he had always disapproved of. Dom Pedro returned to Portugal and bloody battles ensued, in which the British actively took the side of Dom Pedro and the Liberals.
              <br/>
              <br/>
              In 1828 a new Miguelite governor was sent to Madeira together with 1000 troops. A small British force was quashed. Over 150 people were arrested, though many leading liberal figures were able to escape to Britain on the frigate Alligator. Dom Miguel was eventually beaten in 1834, and exiled to Austria. The monarchy weathered badly the political turbulence and economic desiccation that followed the Miguelite rebellion. Finally, in 1910 Dom Manuel II (1908-1910) fled to Britain and Portugal was proclaimed a republic.
             </p>
             <h2>
              THE TWENTIETH CENTURY
             </h2>
             <p>
              On 23 July 1905, the Paris edition of the New York Herald carried a report headed: "German Company Plans to Make Madeira an up-to-date Resort". In return for a promise to build a sanatorium and hospitals and treat 40 TB patients a year free, the Madeira Actien Gesellschaft, headed by Prince Frederick Karl Hohenlohe, was -by arrangement with the Portuguese government- to take over all business concerns on the island. The British were in a fury. When plans for some of the hospitals were exposed as being designs for hotels and holiday camps, the Portuguese realized that they were being colonized through the back door and promptly withdrew the concession. In 1914 all German property on Madeira was confiscated. Germany declared war on Portugal in 1916 after German ships had been impounded in Lisbon harbor. Madeira got off fairly lightly during the war, though Funchal was twice shelled by German U-boats, once in 1916 and again a year later. The decline in trade during the First World War, the Prohibition in America and the disappearance of the Madeira-drinking classes after the Bolshevik Revolution in Russia knocked a large hole in the Madeiran economy. The mainland wasn't faring all that well either. The new Republic began to founder -there were 45 governments between 1910 and 1926. Shortly after a military coup in 1926, Dr.Antonio de Oliveira Salazar became minister of finance. By 1932 he was Prime Minister and remained a virtual dictator until leaving office after a stroke in 1968. Although he balanced the books, his regime became increasingly cruel and repressive. In 1974, after a bloodless coup, his successor Dr.Marcello Caetano was overthrown and the basis of the modern state of Portugal was established.
              <br/>
              <br/>
              Madeira began the Salazar years on a bad footing. In 1931 a dispensation which became known as the Hunger Law gave a monopoly to a small group of local mill-owners. This not only caused a rise in the price of bread, but also had a domino effect on investors and companies throughout the Madeiran economy, which eventually led to the collapse of the island's two main banks. Thousands lost their savings. The mills were attacked and a general strike broke out. Lisbon sent troops, but some of the soldiers defected, joining the local garrison and a group of 300 political prisoners (who had been deported from the mainland a few month previously) to stage a coup. General Sousa Dias, one of the deportees, was declared dictator. Many British residents hopped aboard the passing Edinburgh Castle and left the island. Others holed up in the luxury hotels around Reid?s, protected by a small group of fusiliers and marines that had hurried over from Gibraltar. A punitive force arrived from Lisbon at the end of April, and the rebels were soon defeated. Some of the ringleaders sought asylum with the British, but most of those who had been involved in the fighting, including Dias himself, were imprisoned and taken back to Portugal.
              <br/>
              <br/>
              From then on Madeira kept her grumbles to herself. Portugal remained neutral during the Second World War -though once again the decline in trade and tourism was bad for the island economy. The 1974 Revolution was greeted with rejoicing, even though it too meant a momentary blow to tourism. Local supporters of Salazar made a quick break for Brazil and new political parties sprang up. But within a year civil war loomed. On Madeira, as on the mainland, there was concern about growing communist influences. A right-wing separatist movement Frente de Libertacao Madeirense (FLAME, the Madeiran Liberation Front) demanded complete independence from Lisbon and is believed to have been responsible for acts of sabotage around the island. On the mainland a counter-coup was defeated in 1975, and in 1976 a new constitution was drawn up. Under this constitution Madeira was given special status within Portugal as an Autonomous Political Region. The Social Democratic Party (PSD) on the island, under Dr.Alberto Joao Jardim, polled 65 per cent of the votes, while the Communists (PCP) scraped a mere 0.6 per cent. These proportions remain pretty much the same today, with a handful of other parties filling the gap and Dr.Jardim still very much at the helm.
              <br/>
              <br/>
              President Jardim has proved a dab hand at landing EU subsidies for Madeira. Hundreds of billions of escudos have gone into building new roads, giving a boost to the tourist industry and establishing a Free Trade Zone. All this is changing the face of an economy that once relied heavily on farming and fishing. Today 46 per cent of Madeirans are in work, more than half of those in tourism and the service, and just over 14 per cent in agriculture and fishing. While villages that have a link with the tourist industry are booming, many others are in dire straits. In places people live in shocking poverty. The average island annual income is half the European norm -an unskilled worker can expect to bring in just 1 milj. Escudos a year. Young people don't want to farm the tiny terraces where labour is backbreaking and any sort of mechanization impossible. A ban on whaling and depletion in fish stocks has put many fishermen out of work. New jobs in the service industries are predominantly for women, and many men turn to alcohol for consolation.
              <br/>
              <br/>
              Despite the trapping of luxury that surround most tourist, Madeira remains one of the most deprived areas of Europe. It looks forward to a period of growth, but all the signs are that growing pains will be servere.
             </p>
            </td>
           </table>
           <hr/>
           <center>
            <table width="80%">
             <td>
              <h2>
               <b>
                Thoughts Upon Slavery by Jorge Castro-Correia
               </b>
              </h2>
              <p>
               At some point in history, slavery has touched nearly every part of the world. As with most places, Madeiran slaves were mainly captured in Africa by more or less violent means. Warfare was the most common method, with the enemy usually being sold off into enslavement. The African societies that practiced slavery usually traded these slaves in the 15th century with the Portuguese and later with the Spanish, Dutch, French and the British.
              </p>
              <p>
               The Portuguese were particularly keen to explore Africa; at the same time they had started up a colony in Madeira, and required labour to work on the much needed irrigation system, the levadas, which would water the recently introduced grain crops. The first slaves to arrive on Madeira were in 1452 and contrary to popular belief, were in fact the Moors, Berbers and the Guanches (natives from the Canary Islands). Nevertheless, by 1462 Pope Pius II declared that baptized Africans should not be enslaved. According to historical records, slaves to Madeira were mainly imported from Northern Africa. However, Tristao Vaz de Veiga, in 1582 (Governor General to the archipelago), had twelve Indian slaves in his possession and the most highly prized slaves on the island were the black slaves from Guinea who were valued for their robust constitution and strength. Their labour was, as already mentioned, initially used in the back breaking task of building the levadas. This vast network of channels transported the much needed water to all parts of the island to irrigate the valuable grain crops. The slaves were used to craft these channels out of the mountains, while hanging from baskets over the steep cliffs, many of whom lost their lives in the process. Nevertheless, by 1552, 3000 slaves could be found on the island. We are told by Count Guilio Landi, who visited Madeira in 1530, that the "trading for slaves called for great care because it was by no means sufficient to merely parade the slave back and forth. A careful physical examination had to be carried out to establish whether the body had any flaws, such as missing teeth, which could make the slave physically weaker than an healthy one still in possession of all his teeth."
              </p>
              <p>
               The tendency in Madeira was for the existence of a reduced number of slaves per landlord. The most slaves ever owned by any individual was 14 slaves belonging to Joao Esmeraldo. Unfortunately, by1485 famine broke out and the failing grain crops had to be replaced by the more profitable sugarcane production. Sugarcane had actually been introduced to Madeira in 1432 as a complimentary crop. There is still some controversy over the status of the Madeiran labourers used in the cultivation of sugar. Alberto Vieira, a highly respected expert in trans-Atlantic slavery, states that in Madeira " the records show a high slave concentration in the urban areas revealing that we are faced with slavery of a domestic nature, with little or no relation to rural life." However, records do also show that slaves were indeed used in the sugar mills. Jean Moquet in his 1601 journals states that he personally saw "a large number of slaves working on the sugar outside town".
              </p>
              <p>
               There is admittedly a pragmatic tendency to consider the effects of slavery in purely monetary terms. Slaves were also, however, sometimes used as spies as is in Damiao, a slave belonging to Dona Branca de Atouguia, who upon the death of her first husband, began to rely on his intelligence and discretion. It is important to understand that slavery was not regarded by the Portuguese as necessarily a permanent condition. Slaves were for instance allowed to marry free spouses and become members of their slave master's families. Freeborn women and male slaves frequently married, and their children were considered to be free. It is important to point out too that the Madeiran aristocracy at no point bred slaves or subjected them to the harsh regimented conditions found on slave plantations in Brazil, the West Indies or the rest of the Americas. Slave owners were only a small minority of the Madeiran population, and those who did own slaves owned only a few.
              </p>
              <p>
               Most slaves were considered status symbols of prestige, luxury and power rather than a source of pure labour, so consequently a complimentary labour force. The treatment of slaves was also somewhat different due to the islands unique geographic conditions and limited land area. With the increased amount of slaves on the island by 1470 a law was created declaring that slaves seen on the streets after dark would be treated as fugitives. The rule equally applied even if the slave had been sent by their owners on important errands. Slaves were understandably also not allowed to bear arms or carry staffs. By 1473 slaves were furthermore legally forbidden from owning property and by 1481 it became compulsory for slaves to be visibly brandished on their chest or arm as public symbols of bondage. Having in mind the restrictions imposed by the by-laws to the circulation of the slaves after curfew, it seems difficult, if not impossible, to find a moment where the slaves would be in each other's company and enjoy themselves with songs and dances.
              </p>
              <p>
               Interestingly, if a slave were to run away they could be sold again by the person who managed to catch him. This rule only applied if the owner had failed to retrieve his slave within four months of his inicial escape. However, those slaves that struck their master or mistress could be put to death. The owners of slaves were also legally entitled to cut off one of the slave's ears if he was disobedient or ran way; their hamstrings could legally be severed on the second attempt and they could legally be put to death on the third attempt. There is no record of this practice actually ever being carried out. However, records do show that in Calheta in 1505 Joao Rodrigues Castelhano had five of his slaves hung, for assassinating his farm manager.
              </p>
              <p>
               Nevertheless, the treatment of slaves was in general very descent if compared with other places where slavery was to be found. Even so, slavery was not an open-and-shut case. Some slaves for instance even managed to achieve exalted status. Records show that Dona Branca de Atouguia appointed Damiao  her slave as manager of her estate. She also legally guaranteed the freedom of her slaves upon her death. The setting free of slaves after the death of their owners was customary on the island. She also organised for two of her slaves to be admitted to the convent of Sao Bernardino upon her death. Owners were also required to have their slaves baptised and instructed in the Catholic faith as were any children who were the fruit of slave/master relationships, who would then be accepted into Madeiran society. By 1505 Bishop D. Luis de Figueiredo ordered all local slave owners to ensure that slaves of both sexes who were 'living in sin' should get married. All the clergy in the Bishopric of Funchal were forbidden from keeping female slaves who were under the age of fifty.
              </p>
              <p>
               By 1537 Pope Paul lll began to voice serious concerns about the condition in which the slaves were kept. However, the abolition of slavery in Madeira only occurred officially in November 1767 when a ship anchored off the port of Funchal, carrying a shipment of 300 slaves, was prohibited from off loading the slaves. With Slavery finally brought to an end the influence and existence of slavery on Madeira can still be seen today, visible in some of the street names like Rua da Mouraria (street in the Moorish quarter), or even Rua das Pretas (Street of the black women); visible too in its influence of Madeiran Portuguese food with its distinctive culinary heritage and diverse flavours, which includes the flavours of the African continent and Portugal. While the Portuguese were busy establishing a new society, they did not realize that the African slaves who worked for them brought with them their own vibrant and rich culture. A culture that would withstand and adapt to the harsh centuries of slavery. Slaves established their own cooking culture using foods that were similar to foods that were part of their African heritage, and many popular foods in the Madeiran diet are directly associated with foods in Africa. For instance, the Arabic Kebab has direct links with the traditional Madeiran Espetada; a local favourite in Madeiran homes is Milho , a type of polenta made from corn meal, which is a lasting culinary vestige of slavery, as is indeed couscous. The influence of slavery on today's Madeiran cultural heritage can also be seen in the music and folk dancing. Many of these folk dances have become so typically Madeiran that it can be difficult to discern these influences, however, dancing in a circle while bowing your head has a distinctly African origin.
              </p>
              <p>
               There is no doubt that the negative impact of slavery is countered by positive aspects in which slavery has enriched Madeira and added to its cultural heritage. This does not in any way condone slavery, nor does it explain this dark chapter in the history of Madeira.
              </p>
              <hr/>
             </td>
            </table>
           </center>
          </center>
         </td>
        </tr>
       </table>
       <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
         <td>
         </td>
        </tr>
        <tr>
         <td>
         </td>
        </tr>
       </table>
      </td>
     </table>
    </td>
   </tr>
  </table>
 </body>
</html>
`
const replacements = {
}
Object.entries(replacements).forEach(([key, value]) => {
htmlAsString = htmlAsString.replace(key, value)
})
export default <div dangerouslySetInnerHTML={{__html: htmlAsString}}/>