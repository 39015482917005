import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

export default function Countdown({endDate}) {
  // let startDate = new Date(endDate)
  // startDate.setFullYear( startDate.getFullYear() - 1 );
  const startDate = new Date()
  const remainingTime = endDate > startDate ? Math.abs(endDate - startDate) / 1000 : 0

  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div style={{
        display: "flex",
        gap: 20,
        textAlign: "center"
      }}>
      <CountdownCircleTimer
        {...timerProps}
        // colors="#A9A9A9"
        // colors="#A9A9A9"
        colors="green"
        duration={daysDuration}
        // duration={365 * daySeconds}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#FFD700"
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#DA291C"
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#1181EC"
        // colors="#263E78"
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > 0
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("seconds", getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
    </div>
  );
}



// import react, {useState} from 'react'
// import './Countdown.css';

// const moment = require('moment'); // require

// export default function Countdown({eventDate}) {
//     const calculateTimeLeft = () => {
//         const timeLeftMs = Math.abs(new Date() - eventDate)
//         return Math.round(timeLeftMs / 1000)  // seconds
//     }
//     const [timeLeft, updateTimeLeft] = useState(calculateTimeLeft())
//     requestAnimationFrame(updateTime)
//     function updateTime() {
//         // console.log("Here")
//         // document.documentElement.style.setProperty('--timer-day', "'" + moment().format("DD") + "'");
//         // document.documentElement.style.setProperty('--timer-hours', "'" + moment().format("k") + "'");
//         // document.documentElement.style.setProperty('--timer-minutes', "'80'");
//         // document.documentElement.style.setProperty('--timer-seconds', "'79'");
//         // document.documentElement.style.setProperty('--timer-day', "'22'");
//         // document.documentElement.style.setProperty('--timer-hours', "'10'");
//         // document.documentElement.style.setProperty('--timer-minutes', "'48'");
//         // document.documentElement.style.setProperty('--timer-seconds', "'70'");
//         requestAnimationFrame(updateTime);
//     }
//     console.log(timeLeft)
//     return (
//         <div className="clock-container">
//             <div className="clock-col">
//                 <p className="clock-day clock-timer">
//                     {Math.round(timeLeft / 60 / 60 / 24)}
//                 </p>
//                 <p className="clock-label">
//                     Day
//                 </p>
//             </div>
//             <div className="clock-col">
//                 <p className="clock-hours clock-timer">
//                     {Math.round(timeLeft / 60 / 60) % 24}
//                 </p>
//                 <p className="clock-label">
//                     Hours
//                 </p>
//             </div>
//             <div className="clock-col">
//                 <p className="clock-minutes clock-timer">
//                     {Math.round(timeLeft / 60) % 60}
//                 </p>
//                 <p className="clock-label">
//                     Minutes
//                 </p>
//             </div>
//             <div className="clock-col">
//                 <p className="clock-seconds clock-timer">
//                     {timeLeft % 60}
//                 </p>
//                 <p className="clock-label">
//                     Seconds
//                 </p>
//             </div>
//         </div>
//     )
// }