import react from 'react'

import BackgroundImage, { implyAspectRatio } from './BackgroundImage'
import { Typography, useTheme } from '@mui/material'
import DownloadButton from './DownloadButton'

const DUTY_NOTE = "Note: The Board is elected at an annual Membership meeting in March of each year. The Board Officers are elected annually by a vote of the Board of Directors after the annual membership meeting."

const DUTY_LIST = [
  {
    position: "President",
    duties: [
      "The President shall be the Chief Executive Officer (CEO) of the Corporation and shall, subject to the control and direction of the Board of Directors, have general supervision, direction and control of the business and affairs of the Corporation.",
      "The President shall preside at all meetings of the Corporation and serve as an ex officio member of all committees and perform other duties as may be prescribed by the Board of Directors."
    ]
  },
  {
    position: "Vice-President",
    duties: [
      "The Vice-President, in the absence or disability of the President, shall have all powers and duties of the President and may, from time to time , have duties as prescribed by the Board of Directors."
    ]
  },
  {
    position: "Secretary",
    duties: [
      "Maintains a book of minutes of the Board of Director meetings with time and location indicated as well as names of those in attendance. If a special meeting is called, the minutes should reflect how such special meeting was authorized, the notice sent to the Directors and membership and with names of those present.",
      "Receive correspondence, presenting such at Board of Director meetings and answering correspondence as directed by the Board.",
      "Maintains a copy of the most recent and amended Bylaws with date of adoption. Upon request, makes available copies of the most recent approved Bylaws.",
      "Notify the Board of Directors of all scheduled meetings.",
      "Notify the entire membership of membership meetings, annual or other, by appropriate means.",
      "Maintains current lists of membership with names, email and postal mailing addresses, phone numbers. Other information as may be determined necessary by the Board of Directors. Such information must be guarded to insure privacy.",
      "Other duties as prescribed by the Board of Directors."
    ]
  },
  {
    position: "Treasurer",
    duties: [
      "The Treasurer shall be the Chief Financial Officer (CFO) of the Corporation and shall maintain adequate and correct books and records of accounts of the properties and business transactions of the Corporation.",
      "Receives and Deposits moneys and other valuables in the name and to the credit of the Corporation or as may be ordered by the Board of Directors.",
      "Establishes a central mailbox for receiving all Corporation mail and fund raising submissions. Disbributes such communication to the Secretary, Board and committees as appropropriate .",
      "Disperses funds of the Corporation in payment of just demands against the Corporation or as may be ordered by the Board of Directors",
      "Renders to the President and/or Board of Directors an accounting of all transactions and the financial condition of the Corporation",
      "Develops and maintains, with the Board of Directors, an annual Corporate Budget.",
      "Prepares all City/County/State/Federal reports and submits such, with approval by the Board of Directors, as may be required."
    ]
  },
  {
    position: "Directors",
    duties: [
      "Participates in all issues of governance of the San Pedro Annual Festival.",
      "Participates with/on committees of as may be required.",
      "Presents items for discussion concerning issues for the betterment of the Corporation.",
      "Participates in all discussions and votes on issues concerning the Corporation.",
      "Represents the membership regarding issues of concern and management of the Corporation.",
      "Promotes the purposes and traditions of the San Pedro Annual Festival as stated in the Corporation's \"Articles of Incorporation\"."
    ]
  },
  {
    position: "Membership",
    duties: [
      "Conduct annual \"Friends of San Pedro\" fund drive.",
      "Maintain and co-ordinate membership rosters with board secretary.",
      "Record and report to the Board of progress of the fund drive.",
      "Maintain a record of amounts received by member."
    ]
  },
  {
    position: "History & Heritage",
    duties: [
      "Maintain and document history and artifacts of our San Pedro Annual Festival.",
      "Provide displays of such history and participate in community and cultural events as may be warranted.",
      "Be a resource for information about our Madeiran culture and the San Pedro Annual Festival."
    ]
  },
  {
    position: "Special Committees",
    duties: [
      "Appointed by the President, with approval by the Board, as may be required for specific functions or activities. Such committees shall have specific responsible duties and serve for the period of time as determined by the President."
    ]
  },
  {
    position: "Standing Committees",
    duties: [
      "Appointed by the Board, as may be required for specific functions or activities. Such committees shall have specific responsible duties and serve for the period of time as determined by the Board."
    ]
  }
]

export default function DutiesOfTheBoard() {
  return (
    <div style={{
      width: "40vw"
    }}>
      <Typography variant="h6">
        {DUTY_NOTE}
      </Typography>
      <DownloadButton text={"San Pedro Association Bylaws"} url={require("../assets/SanPedroBylaws.pdf")} />
      <br/>
      {DUTY_LIST.map(({ position, duties }) => {
        return (
          <div key={position}>
            <Typography variant="h6">
              <b>{position}</b>
            </Typography>
            <ul>
              {duties.map(d => <li key={d}>
                <Typography align="left" variant="body1">
                  {d}
                </Typography>
              </li>)}
            </ul>
          </div>
        )
      })}
    </div>
  )
}