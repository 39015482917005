let htmlAsString = `<html>
 <head>
  <title>
   Madeira Island
  </title>
 </head>
 <body>
  <hr/>
  <div align="center">
   <h2>
    <em>
     <font color="#0000FF">
      Ilha do Madeira
     </font>
    </em>
   </h2>
   <font size="">
    Below is  its
    <b>
     Concelhos
    </b>
    (municipalities) and
    <b>
     Freguesias
    </b>
    (parishes).
    <br/>
    (Click on the areas within the maps for more info.)
   </font>
   <br/>
   <br/>
   <map name="concelhos">
    <area alt="Porto Moniz" coords="92,21,67,21,67,21,42,55,65,67,82,94,112,122,144,137,160,133,172,117,184,75,153,60,130,59" href="#pmoniz" shape="poly"/>
    <area alt="Sao Vicente" coords="188,76,180,110,166,132,202,127,235,128,271,130,298,122,276,78,272,48,257,47,247,58,216,68" href="#svicente" shape="poly"/>
    <area alt="Santana" coords="279,48,281,79,298,118,315,134,354,153,373,150,378,107,382,76,368,71,371,61,329,31,303,40" href="#santana" shape="poly"/>
    <area alt="Machico" coords="385,85,378,146,402,142,437,151,461,160,473,132,507,117,471,110,404,101" href="#machico" shape="poly"/>
    <area alt="Santa Cruz" coords="362,160,362,176,375,214,388,241,405,237,417,225,433,202,445,181,460,165,405,147,394,147" href="#scruz" shape="poly"/>
    <area alt="Funchal" coords="312,141,308,186,299,216,289,239,309,251,321,249,341,237,378,238,379,228,367,209,359,183,355,160" href="#funchal" shape="poly"/>
    <area alt="Camara de Lobos" coords="275,135,258,161,252,178,250,211,243,233,272,238,285,240,301,185,310,137,302,126" href="#clobos" shape="poly"/>
    <area alt="Ribeira Brava" coords="214,135,183,212,239,235,247,204,249,171,267,136" href="#rbrava" shape="poly"/>
    <area alt="Ponta do Sol" coords="203,132,146,142,129,188,148,202,158,210,177,213,200,153" href="#psol" shape="poly"/>
    <area alt="Calheta" coords="40,61,14,87,30,99,38,129,74,163,84,169,125,186,140,140,90,112,62,71" href="#calheta" shape="poly"/>
   </map>
   <img border="1" height="286" src="smadeira.gif" usemap="#concelhos" width="566"/>
   <br/>
   <a href="http://www.dholmes.com/master-list/madeira-islands.html">
    <font size="-2">
     maps provided by : www.dholmes.com/master-list/madeira-islands.html
    </font>
   </a>
   <p align="center">
    [
    <a href="#calheta">
     Calheta
    </a>
    ] 
[
    <a href="#clobos">
     Camara de Lobos
    </a>
    ]
[
    <a href="#funchal">
     Funchal
    </a>
    ]
[
    <a href="#machico">
     Machico
    </a>
    ]
[
    <a href="#psol">
     Ponta do Sol
    </a>
    ]
    <br/>
    [
    <a href="#pmoniz">
     Porto Moniz
    </a>
    ]
[
    <a href="#rbrava">
     Ribeira Brava
    </a>
    ]
[
    <a href="#santana">
     Santana
    </a>
    ]
[
    <a href="#scruz">
     Santa Cruz
    </a>
    ]
[
    <a href="#svicente">
     Sao Vicente
    </a>
    ]
[
    <a href="spsant.gif">
     Port Santo
    </a>
    ]
    <br/>
    <br/>
   </p>
   <center>
    POPULATION -  Madeira Island, Porto Santo Island
   </center>
   <b>
    Calheta
   </b>
   11,856,
   <b>
    Camara de Lobos
   </b>
   35,150,
   <b>
    Funchal
   </b>
   100,847,
   <b>
    Machico
   </b>
   21,321,
   <b>
    Ponta do Sol
   </b>
   8,189,
   <br/>
   <b>
    Porto Moniz
   </b>
   2,762,
   <b>
    Ribeira Brava
   </b>
   12,523,
   <b>
    Santana
   </b>
   8,491,
   <b>
    Santa Cruz
   </b>
   32,696,
   <b>
    Sao Vicente
   </b>
   6,063,
   <b>
    Porto Santo
   </b>
   4,388
   <br/>
   <b>
    TOTAL
   </b>
   244,286
   <br/>
   <br/>
   <center>
    <b>
     Unpopulated Islands - Madeira
    </b>
   </center>
   <b>
    Desertas Islands
   </b>
   -  Deserta Grande Island, Bugio Island, Chao Islet
   <br/>
   <b>
    Selvagem Islands
   </b>
   (
   <i>
    Dry Salvages
   </i>
   ) -  Selvagem Grande Island, Selvagem Pequena Island,  Palheiro da Terra islet,
   <br/>
   Palheiro do Mar islet, Fora Islet, Alto islet,  Comprido islet, Redondo islet,  Norte Islets
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="pmoniz">
     <em>
      <font color="#0000FF">
       Concelho do Porto Moniz
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <map name="freguesias1">
    <area alt="Achadas da Cruz" coords="46,37,9,72,24,85,46,100,73,136,106,147,118,136,107,105,93,99" href="#achadas" shape="poly"/>
    <area alt="Porto Moniz" coords="52,32,69,14,89,12,108,18,127,16,126,25,147,43,136,54,106,97,97,95" href="http://www.madeira-web.com/PagesUK/portmoniz.html" shape="poly"/>
    <area alt="Ribeira da Janela" coords="148,45,110,99,117,126,144,180,135,185,149,190,165,222,192,232,222,233,188,214,173,180,149,110,173,75" href="#ribeira" shape="poly"/>
    <area alt="Seixal" coords="174,81,153,109,176,146,184,177,182,197,207,219,230,229,272,219,285,208,287,183,303,140,272,128,258,134,230,105,215,110" href="#seixal" shape="poly"/>
   </map>
   <img border="1" height="245" src="spmoniz.gif" usemap="#freguesias1" width="315"/>
   <map name="parishes">
    <p>
     [
     <a href="#achadas">
      Achadas da Cruz
     </a>
     ] 
[
     <a href="http://www.madeira-web.com/PagesUK/portmoniz.html">
      Porto Moniz
     </a>
     ]
[
     <a href="#ribeira">
      Ribeira da Janela
     </a>
     ]
[
     <a href="#seixal">
      Seixal
     </a>
     ]
    </p>
   </map>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="svicente">
     <em>
      <font color="#0000FF">
       Concelho do Sao Vicente
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <map name="freguesias2">
    <area alt="Sao Vicente" coords="156,52,74,70,47,58,37,65,39,74,12,160,86,243,124,240,132,219,141,217,172,231,206,220,241,228,218,210,221,186,216,176,214,131,172,99" href="http://www.madeira-web.com/PagesUK/s-vicente.html" shape="poly"/>
    <area alt="Ponta Delgada" coords="227,12,205,33,162,52,173,93,194,106,204,120,217,126,226,105,231,55,240,49,239,31,251,25" href="#ponta" shape="poly"/>
    <area alt="Boa Ventura" coords="257,16,248,35,235,65,230,106,221,134,221,172,231,215,251,225,285,226,307,209,315,173,302,172,297,155,324,129,320,51" href="#boaventura" shape="poly"/>
   </map>
   <img border="1" height="255" src="svicente.gif" usemap="#freguesias2" width="337"/>
   <p>
    [
    <a href="#boaventura">
     Boa Ventura
    </a>
    ] 
[
    <a href="#ponta">
     Ponta Delgada
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/s-vicente.html">
     Sao Vicente
    </a>
    ]
    <br/>
    [
    <a href="http://www.madeira-web.com/PagesUK/caves-sv.php">
     Caves of Sao Vicente
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="santana">
     <em>
      <font color="#0000FF">
       Concelho do Santana
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <map name="freguesias3">
    <area alt="Arco de Sao Jorge" coords="77,22,34,28,10,33,32,50,44,56,45,65,51,65,68,45" href="arcosj.htm" shape="poly"/>
    <area alt="Sao Jorge" coords="86,23,75,35,63,63,45,71,48,126,26,149,38,164,31,194,75,145,96,101,106,70,131,62,158,54,158,28,146,7" href="#saojorge" shape="poly"/>
    <area alt="Ilha" coords="159,57,146,68,128,66,104,81,100,94,102,105,77,156,37,197,60,200,107,186,138,129,164,72,162,46" href="#ilha" shape="poly"/>
    <area alt="Santana" coords="164,29,166,85,134,144,112,184,119,186,196,152,223,130,223,82,240,87,206,65,188,35" href="http://www.madeira-web.com/PagesUK/santana.html" shape="poly"/>
    <area alt="Faial" coords="226,88,230,131,226,144,121,191,65,202,75,219,66,244,97,237,115,242,127,234,154,244,170,232,193,212,250,159,262,152,263,170,268,173,275,151,261,117,240,115,237,100,249,86" href="#faial" shape="poly"/>
    <area alt="Sao Roque do Faial" coords="233,182,259,160,242,193,242,229,206,244,179,250,171,299,165,304,139,302,127,284,118,291,91,273,88,254,69,249,101,242,111,246,128,240,155,249,193,225" href="#sroque" shape="poly"/>
   </map>
   <img border="1" height="311" src="santana.gif" usemap="#freguesias3" width="285"/>
   <p>
    [
    <a href="arcosj.htm">
     Arco de Sao Jorge
    </a>
    ] 
[
    <a href="#faial">
     Faial
    </a>
    ]
[
    <a href="#ilha">
     Ilha
    </a>
    ]
[
    <a href="#sjorge">
     Sao Jorge
    </a>
    ]
[
    <a href="#sroque">
     Sao Roque do Faial
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/santana.html">
     Santana
    </a>
    ]
    <br/>
    [
    <a href="http://www.madeira-web.com/PagesUK/ribeiro-frio.html">
     Ribeiro Frio
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="machico">
     <em>
      <font color="#0000FF">
       Concelho do Machico
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <p>
    <map name="freguesias4">
     <area alt="Porto da Cruz" coords="113,7,105,36,93,37,81,80,44,94,32,127,23,139,65,125,87,103,90,87,122,77,136,74,145,79,157,64,182,60,184,49,162,47,152,42,130,36" href="http://www.madeira-web.com/PagesUK/porto-cruz.html" shape="poly"/>
     <area alt="Santo Antonio da Serra" coords="92,103,100,89,118,85,137,81,168,84,169,91,158,123,135,116,105,100" href="http://www.madeira-web.com/PagesUK/santo-serra.html" shape="poly"/>
     <area alt="Machico" coords="190,56,188,62,166,65,160,75,176,88,163,116,183,120,193,121,225,139,232,127,224,96,226,59,206,61" href="http://www.madeira-web.com/PagesUK/machico.html" shape="poly"/>
     <area alt="Canical" coords="231,65,239,123,258,99,286,91,325,92,336,80,330,76,308,77,275,77" href="http://www.madeira-web.com/PagesUK/canical.html" shape="poly"/>
     <area alt="Agua da Pena" coords="168,125,182,147,206,161,219,160,224,146,188,123" href="#agua" shape="poly"/>
    </map>
    <img border="1" height="171" src="smachico.gif" usemap="#freguesias4" width="390"/>
   </p>
   <p>
    [
    <a href="#agua">
     Agua da Pena
    </a>
    ] 
[
    <a href="http://www.madeira-web.com/PagesUK/canical.html">
     Canical
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/machico.html">
     Machico
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/porto-cruz.html">
     Porto da Cruz
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/santo-serra.html">
     Santo Antonio da Serra
    </a>
    ]
    <br/>
    [
    <a href="http://www.madeira-web.com/PagesUK/airport.html">
     Madeira Airport
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/s-lourenco.html">
     Ponta de Sao Lourenco
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="calheta">
     <em>
      <font color="#0000FF">
       Concelho do Calheta
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <br/>
   <map name="freguesias5">
    <area alt="Ponta do Pargo" coords="55,9,8,50,23,65,34,91,65,93,90,81,138,79,138,71,115,62" href="http://www.madeira-web.com/PagesUK/ponta-pargo.html" shape="poly"/>
    <area alt="Faja da Ovelha" coords="156,62,136,84,95,88,65,97,37,96,38,124,40,132,60,130,75,154,101,117,133,102,167,98" href="http://www.madeira-web.com/PagesUK/faja-ovel.html" shape="poly"/>
    <area alt="Paul do Mar" coords="46,134,48,144,61,157,73,174,73,158,64,145,59,138" href="http://www.madeira-web.com/PagesUK/paul-mar.html" shape="poly"/>
    <area alt="Jardim do Mar" coords="77,175,82,191,94,199,94,189" href="http://www.madeira-web.com/PagesUK/jardim-mar.html" shape="poly"/>
    <area alt="Prazeres" coords="160,105,124,110,98,128,77,157,79,169,96,186,101,203,109,161,135,147,143,129" href="http://www.madeira-web.com/PagesUK/prazeres.html" shape="poly"/>
    <area alt="Estreito da Calheta" coords="175,101,150,125,142,150,123,157,113,171,106,203,120,216,138,199,154,155,188,118" href="#estreito" shape="poly"/>
    <area alt="Calheta" coords="188,129,159,155,146,196,127,220,151,232,205,166,259,169,298,137,297,128,257,145,202,143" href="http://www.madeira-web.com/PagesUK/calheta.html" shape="poly"/>
    <area alt="Arco da Calheta" coords="206,172,155,235,191,256,209,224,226,214,240,179,255,182,255,173" href="http://www.madeira-web.com/PagesUK/arco-calheta.html" shape="poly"/>
   </map>
   <img border="1" height="265" src="scalheta.gif" usemap="#freguesias5" width="306"/>
   <p>
    [
    <a href="http://www.madeira-web.com/PagesUK/arco-calheta.html">
     Arco da Calheta
    </a>
    ] 
[
    <a href="http://www.madeira-web.com/PagesUK/calheta.html">
     Calheta
    </a>
    ]
[
    <a href="#estreito">
     Estreito da Calheta
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/faja-ovel.html">
     Faja da Ovelha
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/jardim-mar.html">
     Jardim do Mar
    </a>
    ]
    <br/>
    [
    <a href="http://www.madeira-web.com/PagesUK/paul-mar.html">
     Paul do Mar
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/ponta-pargo.html">
     Ponta do Pargo
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/prazeres.html">
     Prazeres
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="psol">
     <em>
      <font color="#0000FF">
       Concelho do Ponta do Sol
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <br/>
   <map name="freguesias6">
    <area alt="Madalena do Mar" coords="28,157,33,144,33,181,46,196,52,212,58,236,26,210,8,199,19,163" href="#madalena" shape="poly"/>
    <area alt="Canhas" coords="159,21,116,50,106,84,85,72,70,90,65,127,40,145,40,179,53,201,64,239,87,262,89,196,106,139,135,88,151,83" href="#canhas" shape="poly"/>
    <area alt="Ponta do Sol" coords="181,7,164,20,155,89,135,98,123,118,107,159,100,178,93,264,102,277,117,270,138,278,196,159,212,108,248,77" href="http://www.madeira-web.com/PagesUK/ponta-sol.html" shape="poly"/>
   </map>
   <img border="1" height="287" src="spsol.gif" usemap="#freguesias6" width="257"/>
   <p>
    [
    <a href="#canhas">
     Canhas
    </a>
    ] 
[
    <a href="#madalena">
     Madalena do Mar
    </a>
    ]
[
    <a href="http://www.madeira-web.com/PagesUK/ponta-sol.html">
     Ponta do Sol
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="rbrava">
     <em>
      <font color="#0000FF">
       Concelho do Ribeira Brava
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <br/>
   <map name="freguesias7">
    <area alt="Tabua" coords="113,38,99,48,85,70,72,69,12,219,37,228,78,155,90,156,94,67" href="#tabua" shape="poly"/>
    <area alt="Ribeira Brava" coords="100,99,101,127,94,154,43,231,90,258,109,222,160,183,197,131,177,113,139,155,126,155,108,136,112,111,111,96" href="http://www.madeira-web.com/PagesUK/ribeira-brava.html" shape="poly"/>
    <area alt="Campanario" coords="212,123,191,147,171,187,117,225,97,257,132,274,156,256,180,249,197,204,216,137" href="#campanario" shape="poly"/>
    <area alt="Serra de Agua" coords="155,17,133,36,103,60,100,80,97,91,116,94,121,118,112,133,122,150,145,141,161,122,180,107,198,123,211,117,230,124,233,106,248,99,242,63,247,26,229,24,214,14,184,31" href="#serra" shape="poly"/>
   </map>
   <img border="1" height="308" src="srbrava.gif" usemap="#freguesias7" width="263"/>
   <p>
    [
    <a href="#campanario">
     Campanario
    </a>
    ] 
[
    <a href="http://www.madeira-web.com/PagesUK/ribeira-brava.html">
     Ribeira Brava
    </a>
    ]
[
    <a href="#serra">
     Serra de Agua
    </a>
    ]
    <br/>
    [
    <a href="#tabua">
     Tabua
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="clobos">
     <em>
      <font color="#0000FF">
       Concelho do Camara de Lobos
      </font>
     </em>
    </a>
   </h2>
   <br/>
   <br/>
   <map name="freguesias8">
    <area alt="Curral das Freiras" coords="112,54,112,105,94,110,117,165,148,171,152,139,174,126,172,113,205,86,215,88,224,76,206,61,209,33,196,12,169,11,155,23,114,28" href="http://www.madeira-web.com/PagesUK/curral-freiras.html" shape="poly"/>
    <area alt="Jardim da Serra" coords="81,129,64,193,76,213,81,209,92,216,109,207,120,179,110,162,102,161,98,127" href="#jardim" shape="poly"/>
    <area alt="Estreito da Camara de Lobos" coords="128,183,111,215,97,222,83,216,80,235,113,243,120,255,141,244,144,252,152,238,159,241,161,219,149,207,147,179" href="#estreito" shape="poly"/>
    <area alt="Quinta Grande" coords="59,204,46,243,10,258,17,266,50,280,62,258" href="#quinta" shape="poly"/>
    <area alt="Camara de Lobos" coords="71,229,58,278,131,298,142,290,147,276,154,265,152,255,120,264,109,250,77,245" href="http://www.madeira-web.com/PagesUK/camara-lobos.html" shape="poly"/>
   </map>
   <img border="1" height="314" src="sclobos.gif" usemap="#freguesias8" width="237"/>
   <p>
    [
    <a href="#concelho">
     Somewhere in the conselho
    </a>
    ] 
[
    <a href="http://www.madeira-web.com/PagesUK/camara-lobos.html">
     Camara de Lobos
    </a>
    ] 
[
    <a href="http://www.madeira-web.com/PagesUK/curral-freiras.html">
     Curral das Freiras
    </a>
    ]
[
    <a href="#estreito">
     Estreito da Camara de Lobos
    </a>
    ]
    <br/>
    [
    <a href="#jardim">
     Jardim da Serra
    </a>
    ]
[
    <a href="#quinta">
     Quinta Grande
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="funchal">
     <em>
      <font color="#0000FF">
       Concelho do Funchal
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    .
   </font>
   <br/>
   <br/>
   <p>
    <map name="freguesias9">
     <area alt="Santo Antonio" coords="95,28,85,28,53,53,25,87,24,154,38,175,33,218,47,238,111,247,119,233,94,199,105,132,117,68" href="#santonio" shape="poly"/>
     <area alt="Sao Roque" coords="103,21,101,29,123,66,122,105,104,164,102,202,123,232,129,224,128,207,145,161,131,111,126,61" href="#sroque" shape="poly"/>
     <area alt="Monte" coords="110,16,131,62,138,106,149,165,143,207,187,214,205,187,247,148,206,107,161,41,154,48" href="http://www.madeira-web.com/PagesUK/monte.html" shape="poly"/>
     <area alt="Sao Martinho" coords="32,229,8,273,46,284,71,312,123,297,122,274,109,250,48,245" href="#smartinho" shape="poly"/>
     <area alt="Imaculado Coracao de Maria" coords="136,211,129,235,155,249,156,234,165,216" href="#imaculado" shape="poly"/>
     <area alt="Sao Pedro" coords="125,237,116,252,123,273,133,271,140,262,160,260,157,255,135,239" href="#spedro" shape="poly"/>
     <area alt="Santa Luzia" coords="168,217,158,237,161,257,176,258,185,251,181,228,190,219" href="#sluzia" shape="poly"/>
     <area alt="Se" coords="170,259,143,266,127,276,126,286,168,290,142,282,170,271" href="#se" shape="poly"/>
     <area alt="Santa Maria Maior" coords="247,156,194,207,189,226,186,233,174,270,208,274,217,241,228,201" href="#smaria" shape="poly"/>
     <area alt="Sao Goncalo" coords="249,177,234,195,215,271,284,294,289,288,277,257,286,240,265,224" href="#sgoncalo" shape="poly"/>
    </map>
    <img border="1" height="323" src="sfunchal.gif" usemap="#freguesias9" width="299"/>
   </p>
   <p>
    [
    <a href="#imaculado">
     Imaculado Coracao de Maria
    </a>
    ] 
[
    <a href="http://www.madeira-web.com/PagesUK/monte.html">
     Monte
    </a>
    ]
[
    <a href="#sluzia">
     Santa Luzia
    </a>
    ]
[
    <a href="#smaria">
     Santa Maria Maior
    </a>
    ]
    <br/>
    [
    <a href="#santonio">
     Santo Antonio
    </a>
    ]
[
    <a href="#sgoncalo">
     Sao Goncalo
    </a>
    ]
[
    <a href="#smartinho">
     Sao Martinho
    </a>
    ]
[
    <a href="#spedro">
     Sao Pedro
    </a>
    ]
[
    <a href="#sroque">
     Sao Roque
    </a>
    ]
[
    <a href="#se">
     Se
    </a>
    ]
    <br/>
    [
    <a href="http://www.madeira-web.com/PagesUK/monte-toboggan.html">
     Monte Wicker Sledge Ride
    </a>
    ]
   </p>
  </div>
  <hr/>
  <div align="center">
   <h2>
    <a name="scruz">
     <em>
      <font color="#0000FF">
       Concelho do Santa Cruz
      </font>
     </em>
    </a>
   </h2>
   <font size="-1">
    The
    <em>
     concelho
    </em>
    and its
    <b>
     freguesias
    </b>
    (parishes).
   </font>
   <br/>
   <br/>
   <map name="freguesias10">
    <area alt="Camacha" coords="73,47,9,75,38,113,45,111,61,139,60,162,72,197,92,188,154,179,125,138,129,123,143,128,143,110,90,92" href="http://www.madeira-web.com/PagesUK/camacha.html" shape="poly"/>
    <area alt="Canico" coords="158,185,138,188,126,199,113,194,105,200,98,196,81,203,93,214,82,232,90,243,92,263,108,271,108,265,157,260,201,217,169,200" href="#canico" shape="poly"/>
    <area alt="Gaula" coords="131,137,160,178,184,200,204,207,219,191,221,169,188,146" href="#gaula" shape="poly"/>
    <area alt="Santa Cruz" coords="221,54,215,64,206,63,187,102,167,108,159,100,147,117,162,137,202,147,226,164,248,136,280,129,296,102,277,103,244,83" href="#scruz" shape="poly"/>
    <area alt="Santo Antonio da Serra" coords="110,13,81,47,88,68,100,90,121,95,147,102,160,95,169,102,190,85,207,55,222,52,210,47,203,50,147,22" href="#santonio" shape="poly"/>
   </map>
   <img border="1" height="285" src="sancruz.gif" usemap="#freguesias10" width="305"/>
   <p>
    [
    <a href="http://www.madeira-web.com/PagesUK/camacha.html">
     Camacha
    </a>
    ] 
[
    <a href="#canico">
     Canico
    </a>
    ]
[
    <a href="#gaula">
     Gaula
    </a>
    ]
[
    <a href="#scruz">
     Santa Cruz
    </a>
    ]
[
    <a href="#santonio">
     Santo Antonio da Serra
    </a>
    ]
   </p>
  </div>
  <hr/>
 </body>
</html>
`
const replacements = {
"smadeira.gif": require("./assets/smadeira.gif"),
"spmoniz.gif": require("./assets/spmoniz.gif"),
"svicente.gif": require("./assets/svicente.gif"),
"santana.gif": require("./assets/santana.gif"),
"smachico.gif": require("./assets/smachico.gif"),
"scalheta.gif": require("./assets/scalheta.gif"),
"spsol.gif": require("./assets/spsol.gif"),
"srbrava.gif": require("./assets/srbrava.gif"),
"sclobos.gif": require("./assets/sclobos.gif"),
"sfunchal.gif": require("./assets/sfunchal.gif"),
"sancruz.gif": require("./assets/sancruz.gif"),
}
Object.entries(replacements).forEach(([key, value]) => {
htmlAsString = htmlAsString.replace(key, value)
})
export default <div dangerouslySetInnerHTML={{__html: htmlAsString}}/>