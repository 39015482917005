import React, { useState, useEffect } from 'react'
import { Paper, Typography } from '@mui/material'

function ContactForm() {
  return <div>
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdtE3PE6xANdFGkQey4P7gnGoULldWVE6tVjMgIIxLSllP27A/viewform?embedded=true"
      width="640" height="830" frameBorder="0" marginHeight="0" marginwidth="0">Loading…</iframe>
  </div>
}

function Address() {
  return (
    <Paper style={{
      display: "flex",
      flexDirection: "row",
      gap: 100,
      padding: 20
    }}>
      <Typography align="left">
        <b>Our Mailing Address</b><br/>
        San Pedro Association<br/>
        PO Box 885<br/>
        West Sacramento, CA 95605
      </Typography>
      <Typography align="left">
        <b>Our Street Address</b><br/>
        San Pedro Park<br/>
        708 N. Hobson Ave.<br/>
        West Sacramento, CA 95605
      </Typography>
    </Paper>
  )
}

export default function ContactUsPage() {
  return (
    <div className='faded-background' style={{
      "--b": `url(${require("../assets/SanPedroPark4.jpg")})`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 20,
      gap: 20
    }}>
      <Address/>
      <ContactForm />
    </div>
  )
}