import React, {useEffect, useState} from 'react'

export async function implyAspectRatio(src, callback) {
  // Hack to determine aspect ratio below
  const img = new Image();
  img.src = src;
  img.onload = () => {
    const result = [img.width, img.height]
    console.log(src, result, callback)
  };
}

export default function BackgroundImage({ src, width, height, aspectRatio, borderRadius, boxShadow, hoverable }) {
  width = typeof width === "number" ? `${width}px` : width
  height = typeof height === "number" ? `${height}px` : height
  if (height === undefined && width !== undefined && typeof aspectRatio === "number") {
    height = `calc(${width} * ${aspectRatio})`
  } 
  if (width === undefined && height !== undefined && typeof aspectRatio === "number") {
    width = `calc(${height} / ${aspectRatio})`
  } 

  const [style, setStyle] = useState({
    backgroundImage: `url(${src})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: width,
    height: height,
    borderRadius: borderRadius,
    boxShadow: boxShadow
  })
  let onMouseEnter, onMouseLeave;
  if (hoverable) {
    onMouseEnter = () => {
      setStyle({
        ...style,
        border: "solid white 3px",
        margin: "-3px"
      })
    }
    onMouseLeave = () => {
      let newStyle = {...style}
      delete newStyle.border
      delete newStyle.margin
      setStyle(newStyle)
    }
  }
  return (
    <div style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}/>
  )
}