import React, { useState, useEffect } from 'react'

import { Typography, useTheme } from '@mui/material'
import ArticlesOfIncorporation from './ArticleOfIncorporation';
import DutiesOfTheBoard from './DutiesOfTheBoard';
import BoardOfDirectors from './BoardOfDirectors';
import HistoryOfSanPedro from './HistoryOfSanPedro';


function Divider({ title, theme, id }) {
  return (
    <div id={id} className='about-us-divider' style={{
      "--w": "50vw",
      "--c": theme.palette.secondary.main,
      "--b": "4px",
      "--g": "40px",
      "--p": "3em"
    }}>
      <Typography variant="h4" style={{
        color: theme.palette.secondary.contrastText
      }}>
        {title}
      </Typography>
    </div>
  )
}

export default function AboutUsPage() {
  const theme = useTheme()

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 20,
      gap: 20,
      paddingBottom: 20,
    }}>
      <Divider title="Board of Directors" theme={theme} id="board-of-directors" />
      <BoardOfDirectors />
      <Divider title="History of San Pedro" theme={theme} id="history-of-san-pedro" />
      <HistoryOfSanPedro />
      <Divider title="Articles of Incorporation" theme={theme} id="articles-of-incorporation" />
      <ArticlesOfIncorporation />
      <Divider title="Duties of the Board" theme={theme} id="duties-of-the-board" />
      <DutiesOfTheBoard />
    </div>
  )
}