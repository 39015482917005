import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const EVENTS = [
  {
    title: "2015 San Pedro Festa",
    subheader: "June 27, 2015",
    thumbnail: require("../assets/SanPedro2015_Thumbnail.jpg"),
    description: "A very successful Festa. Enjoyed by all. Thanks to the committee and Chair Marissa Sequeira!",
    pictures: [
      require("../assets/SanPedro2015_Flags.jpg"),
      require("../assets/SanPedro2015_LittleQueens.jpg"),
      require("../assets/SanPedro2015_Bingo.jpg"),
      require("../assets/SanPedro2015_Convo.jpg"),
      require("../assets/SanPedro2015_Bebe.jpg")
    ]
  },
  {
    title: "Portuguese in Yolo County",
    subheader: "April 26, 2015",
    thumbnail: require("../assets/Yolo2015_Thumbnail.jpg"),
    description: "Sponsored By San Pedro Association and arranged by SPA Historian Sharon Freitas. Photos by Member Robert Martinez",
    pictures: [
      require("../assets/Yolo2015_PeopleSitting.jpg"),
      require("../assets/Yolo2015_Posters.jpg")
    ]
  },
  {
    title: "San Pedro 92nd Annual Festival",
    subheader: "June 29, 2014",
    thumbnail: null,
    description: "A Great Success!",
    pictures: []
  },
  {
    title: "San Pedro Community Fair",
    subheader: "May 31, 2014",
    thumbnail: null,
    description: "The San Pedro Community Fair/Yard Sale/E-Waste P.U. day went Great. Great food and snocones, lots of bargains, lots of e-waste, weather great, people friendly. A good time was had by all ! A lot of new people introduced to San Pedro and the park. Thanks to all for a Great day!",
    pictures: []
  },
  {
    title: "Annual Easter Egg Hunt for Members, Families and Guests",
    subheader: "April 12, 2014",
    thumbnail: require("../assets/EasterEggHunt2014_Thumbnail.jpg"),
    description: "Prizes, Egg Toss (children/adults), Egg coloring, Hotdogs, Drinks, and more. A good time was had by all at San Pedro Park.",
    pictures: []
  }
]

function EventCard({ event, setModalUrl }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: 450 }} elevation={4}>
      <CardHeader
        title={event.title}
        subheader={event.subheader}
      />
      {
        event.thumbnail &&
        <CardMedia
          component="img"
          image={event.thumbnail}
          onClick={() => {
            console.log(setModalUrl(event.thumbnail))
          }}
        />
      }
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {event.description}
        </Typography>
      </CardContent>
      <CardActions style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
      }}>
        {
          event.pictures.length > 0 &&
          <Button
            variant="outlined"
            expand={expanded ? "true" : "false"}
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            {expanded ? "See Less" : "See More"}
          </Button>
        }
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {
            event.pictures.map(picture => <CardMedia
              key={picture}
              component="img"
              image={picture}
              onClick={() => setModalUrl(picture)} />)
          }
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default function PastEvents({ setModalUrl }) {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 50
    }}>
      {EVENTS.map(event => <EventCard key={event.title} event={event} setModalUrl={setModalUrl} />)}
    </div>
  )
}