import { Button, Tooltip } from '@mui/material'
import BackgroundImage from './BackgroundImage'

export default function SquareIconButton({ src, url, size, tooltip }) {
    const width = typeof size === "number" ? `${size}px` : size
    const boxShadow = "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
    const handleClick = () => {
        window.open(url)
    }
    return (
        <div>
            <Tooltip title={tooltip}>
                <Button onClick={handleClick}>
                    <BackgroundImage src={src} width={width} aspectRatio={1} borderRadius={`calc(${width} / 4)`} boxShadow={boxShadow} hoverable={true} />
                </Button>
            </Tooltip>
        </div>
    )
}