import BackgroundImage, { implyAspectRatio } from './BackgroundImage'
import { Typography, useTheme } from '@mui/material'

export default function HistoryOfSanPedro() {
    return (
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 200
      }}>
        <div style={{
          width: "40vw"
        }}>
          <Typography variant="h6" align="left">
            From the letters of <b>Padre Jose Jardim</b>, on his arrival to Sacramento from Madeira Island, June
            27th, 1922:
          </Typography>
          <br />
          <Typography variant="h6" style={{ margin: "0px 100px" }}>
            <i>"After another 30 hours of travel, dawn in Sacramento, capitol of California, on the 27th of
              June, I arrived at the depot. As soon as I got off the train, I found myself surrounded by a
              group of Portuguese who were waiting for me, including my brothers Pedro and Januario,
              and my sister Joana and their families, whom in part I had not seen for about 17 years. The
              hugs were tight and prolonged, after which they took me in cars to the reception lunch, in
              which greetings were repeated and Madeira, our little land, was warmly remembered! The
              further I get from Madeira, the closer I have her to my heart, because there is no heaven like
              ours, nor friends like those here."</i>
          </Typography>
          <br />
          <Typography variant="h6" align="left">
            Today, descendants of the original founders through the San Pedro Association,
            continue to celebrate and promote the Madeiran culture through traditions, religious
            practices and community engagement. "Friends of San Pedro" supports the efforts of the San
            Pedro Association to preserve, maintain and improve San Pedro Park.
          </Typography>
        </div>
        <BackgroundImage src={require("../assets/PadreJardim.jpg")} width={500} aspectRatio={1088 / 805} borderRadius={20} />
      </div>
    )
  }