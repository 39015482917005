import { Button } from '@mui/material'

const PaypalImage = require('../assets/Paypal.png')

export const PAYPAL_FORM_ID = "san-pedro-donation-paypal-form"
const PAYPAL_HOSTED_BUTTON_ID = "6WQYARAB3B344"
const PAYPAL_WEBSCR_URL = "https://www.paypal.com/cgi-bin/webscr"

export function HiddenPaypalForm() {
    return (
        <form id={PAYPAL_FORM_ID} action={PAYPAL_WEBSCR_URL} method="post" target="_top" style={{ display: "none" }}>
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value={PAYPAL_HOSTED_BUTTON_ID} />
        </form>
    )
}

export default function PaypalButton() {
    const borderRadius = "20px"
    const handleClick = () => {
        document.getElementById(PAYPAL_FORM_ID).submit()
    }
    return (
        <div>
            <Button
                style={{
                    borderRadius: borderRadius,
                    padding: 0
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        backgroundImage: `url(${PaypalImage})`,
                        width: "200px",
                        height: "80px",
                        backgroundSize: "200px 80px",
                        borderRadius: borderRadius,
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                    }}
                >
                    <HiddenPaypalForm />
                </div>
            </Button>

        </div>
    )
}