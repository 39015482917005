import SquareIconButton from "./SquareIconButton"
import BackgroundImage from "./BackgroundImage"
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Logos() {
    return (
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: 20
      }}>
        <SquareIconButton size={80} src={require("../assets/FacebookSquare.png")} 
          url={"https://www.facebook.com/SanPedroAssociation.org/"} />
        <SquareIconButton size={80} src={require("../assets/InstagramSquare.jpg")} 
          url={"https://www.instagram.com/sanpedroassociation/"} />
        <SquareIconButton size={80} src={require("../assets/GoogleMapsSquare.png")} 
          url={"https://www.google.com/maps/place/San+Pedro+Park/@38.5938549,-121.5464816,17z/data=!3m1!4b1!4m6!3m5!1s0x809ad4240ec043a7:0xdf79381dc53a7537!8m2!3d38.5938507!4d-121.5439067!16s%2Fg%2F11bt_grvtd?entry=ttu"} />
      </div>
    )
  }

export default function TopBar() {
    const theme = useTheme()
    return (
      <div 
        id="top-bar"
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: theme.palette.primary.main,
          alignItems: "center",
          padding: "10px 100px"
      }}>
        <BackgroundImage src={require("../assets/Festa.jpg")} width={200} aspectRatio={1} borderRadius={20} />
        <Typography style={{ 
          flex: 1, 
          color: theme.palette.primary.contrastText 
        }} variant="h1">
          <b>San Pedro Association</b>
        </Typography>
        <Logos />
      </div>
    )
  }