import React from 'react'
import GoogleDriveGallery from './GoogleDriveGallery'
import PastEvents from './PastEvents'
import GalleryModal from './GalleryModal'


const SAN_PEDRO_GOOGLE_DRIVE_FOLDER = "1PKgDoMAm6ckGabCY0OJI4Lxwsfw_mHrr"
export default function GalleryPage() {
  const [modalUrl, setModalUrl] = React.useState(null);
  const handleModalClose = () => setModalUrl(null);
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: 100,
      paddingTop: 20,
      paddingBottom: 20
    }}>
      <div style={{
        position: "sticky",
        top: 250,  // hack for now, calculate later
        alignSelf: "flex-start"
      }}>
        <GoogleDriveGallery folderId={SAN_PEDRO_GOOGLE_DRIVE_FOLDER} setModalUrl={setModalUrl}/>
      </div>
      <PastEvents setModalUrl={setModalUrl}/>
      <GalleryModal modalUrl={modalUrl} handleModalClose={handleModalClose}/>
    </div>
  )
}