import { Button } from '@mui/material'
import { Download } from '@mui/icons-material'

const SanPedroCalendar2024 = require("../assets/SanPedroCalendar2024.pdf")

export default function DownloadButton({ text, url }) {
  return (
    <div>
      <a href={url} download={text}>
        <Button variant="contained" endIcon={<Download />}>
          {text}
        </Button>
      </a>
    </div>
  )
}